export default {
  error: {
    notfound: 'Сторінку не знайдено',
    other: 'Виявлено помилку',
  },
  login: {
    title: 'Увійти',
    email: 'Електронна адреса',
    password: 'Пароль',
    button: 'Увійти',
    orsign: 'Або увійти за допомогою',
    forgot: 'Забули пароль?',
    noaccount: 'Не маєте облікового запису?',
    create: 'Створити тут',
    error: 'Введено неправильний логін / пароль',
  },
  menu: {
    searchSRBSailor: 'Пошук ПКМ моряка',
    srbOnSign: 'ПКМ на підпис',
    notFound: '',
    main: 'Головна',
    dashboard: 'Аналітика',
    client: 'Моряк',
    documents: 'Документи в обробці',
    verification: 'Верифікація',
    digitization: 'Оцифровка',
    qualificationDocuments: 'Кваліфікаційні документи',
    qualificationDocument: 'Кваліфікаційний документ',
    educationDocuments: 'Освітні документи',
    document: 'Документ',
    educationDocument: 'Освітній документ',
    experienceDocuments: 'Стаж',
    medicalDocuments: 'Медичні документи',
    clientPassport: 'Паспорт моряка',
    clientPassports: 'Паспорти моряка',
    recordBook: 'Послужна книжка',
    recordBooks: 'Послужні книжки',
    verificationQualificationDocuments: 'Верифікація кваліфікаційних документів',
    verificationEducationDocuments: 'Верифікація освітніх документів',
    verificationMedicalDocuments: 'Верифікація медичних документів',
    verificationCivilPassport: 'Верифікація паспорту громадянина',
    verificationSeafarerPassport: 'Верифікація посвідчень особи моряка',
    verificationServiceRecord: 'Верифікація послужних книг моряка',
    clientsPhoto: 'Фото моряка',
    verificationClientsPhoto: 'Верифікація фото моряка',
    verificationForeignPassport: 'Верифікація закордонного паспорту',
    verificationExperienceDocuments: 'Верифікація стажу',
    digitizationQualificationDocuments: 'Оцифровка кваліфікаційних документів',
    digitizationEducationDocuments: 'Оцифровка освітніх документів',
    digitizationMedicalDocuments: 'Оцифровка медичних документів',
    digitizationExperienceDocuments: 'Оцифровка стажу',
    verificationClientsAccounts: 'Верифікація облікових записів',
    clientsAccounts: 'Облікові записи',
    clientsAccount: 'Обліковий запис',
    verificationClientsAccount: 'Верифікація облікового запису',
    admin: 'Адмін панель',
    users: 'Користувачі',
    user: 'Користувач',
    userRegistration: 'Створити новий обліковий запис',
    statements: 'Заяви',
    statementsSailor: 'Заяви моряка',
    dpd: 'ДПО',
    dpdOnSign: 'ДПО на підпис',
    dpdSigned: 'ДПО підписані',
    statementDpd: 'Заяви на отримання робочого документа',
    sqc: 'ДКК',
    statementsSQC: 'Заяви на проходження ДКК',
    statementSQC: 'Заява на проходження ДКК',
    statementSailorPassport: 'Заяви на отримання паспорту моряка',
    statementRecordBook: 'Заяви на отримання ПКМ',
    statementQualification: 'Заяви на отримання кваліфікаційного документа',
    documentRecordBook: 'Видача ПКМ',
    documentWorkSign: 'Робочі документи на підпис',
    protocolsSQC: 'Протоколи ДКК',
    protocolSQC: 'Протокол ДКК',
    eds: 'ЕЦП',
    analyticsExamSQC: 'Аналітика',
    examSQC: 'Аналітика іспитів ДКК',
    'create-statements': 'Створення заяви',
    createStatementQualificationDocuments: 'ДПВ',
    createStatementQualification: 'Створення заяви ДПВ',
    watermark: 'Водяний знак підпису',
    workBook: 'ПКМ',
    createWorkBook: 'Створення заяви ПКМ',
    report: 'Звіти',
    reportSQC: 'ДКК',
    reportSRB: 'ПКМ',
    documentsValid: 'Дійсні документи',
    'replacement-qualification': 'Заяви на заміну РД',
    settings: 'Налаштування',
    documentsFailed: 'Проблемні документи',
    'service-record-book': 'Заяви ПКМ',
    'state-qualification-commission': 'Заяви ДКК',
    cashInflow: 'Надходження грошових коштів',
    reportUnprocessedTransactions: 'Необроблені транзакції',
    medicalCertificates: 'Медичні сертифікати',
    medicalCertificate: 'Медичний сертифікат',
    serviceRecordBooks: 'Послужні книги моряка',
    serviceRecordBook: 'Послужна книга',
    serviceRecordBookLine: 'Запис до послужної книги',
    sailorPassport: 'Посвідчення особи моряка',
    experienceReferences: 'Довідки про стаж',
    experienceReference: 'Довідка про стаж',
    srbStatements: 'Заяви на отримання ПКМ',
    srbStatement: 'Заява на отримання ПКМ',
    passportsCivil: 'Паспорт громадянина',
    passportsForeign: 'Закордонний паспорт',
    seafarerPassportStatements: 'Заяви на отримання посвідчення особи моряка',
    seafarerPassportStatement: 'Заява на отримання посвідчення особи моряка',
    qualificationStatements: 'Заяви на отримання кваліфікаційних документів',
    qualificationStatement: 'Заява на отримання кваліфікаційного документа',
    sqcStatements: 'Заяви ДКК',
    qualificationReplacementStatement: 'Заява на заміну робочого документа',
    civilPassports: 'Громадянські паспорти',
    civilPassport: 'Паспорт громадянина',
    foreignPassports: 'Закордонні паспорти',
    foreignPassport: 'Закордонний паспорт',
    statistics: 'Статистика',
    requests: 'Запити',
    createSrbStatements: 'Створення заяви ПКМ',
    createQualificationStatements: 'Створення заяви на отримання кваліфікаційного документа',
    sailorRegistration: 'Реєстрація моряків',
    workDocumentStatements: 'Заяви на отримання кваліфікаційного документа',
    workDocumentStatement: 'Заява на отримання кваліфікаційного документа',
    workDocumentStatementCreate: 'Створення заяви на отримання кваліфікаційного документа',
    workDocumentStatementEditing: 'Редагування заяви на отримання кваліфікаційного документа',
    qualificationDocumentReceiving: 'Отримання кваліфікаційного документа',
    directories: 'Довідники',
    creteDirectories: 'Додати новий довідник',
    information: 'Інформація',
    decisionAppealShort: 'Оскарження рішень',
    decisionAppeal: 'Оскарження рішень Адміністрації судноплавства',
    decisionAppealStatement: 'Заява на оскарження рішення Адміністрації судноплавства',
    decisionAppealStatementCreate: 'Створення заяв на оскарження рішення',
    documentsSailor: 'Документи моряка',
    audit: 'Аудит',
    professionalCompetenceCheck: 'Перевірка професійної компетентності',
    professionalCompetenceCheckStatements: 'Заяви на проведення перевірки професійної компетентності',
    professionalCompetenceCheckStatement: 'Заява на проведення перевірки професійної компетентності',
    professionalCompetenceCheckStatementCreate: 'Створення заяви на проведення перевірки професійної компетентності',
    professionalCompetenceCheckStatementEdit: 'Редагування заяви на проведення перевірки професійної компетентності',
    informationCorrection: 'Виправлення відомостей у ДРДМ',
    informationCorrectionStatements: 'Заяви на виправлення відомостей у ДРДМ',
    informationCorrectionStatement: 'Заява на виправлення відомостей у ДРДМ',
    informationCorrectionStatementCreate: 'Створення заяви на виправлення відомостей у ДРДМ',
    informationCorrectionStatementEdit: 'Редагування заяви на виправлення відомостей у ДРДМ',
    reports: 'Звіти',
    etiCertificates: 'Свідоцтва ЗПМ',
    etiCertificate: 'Свідоцтво ЗПМ',
    etiCertificateAdd: 'Додати свідоцтво ЗПМ',
    etiCertificateEdit: 'Редагувати свідоцтво ЗПМ'
  },
  btn: {
    add: 'Додати',
    back: 'Назад',
    sign: 'Увійти',
    cancel: 'Відхилити',
    save: 'Зберегти',
    approve: 'Схвалити',
    detail: 'Деталі',
    edit: 'Редагувати',
    saveAndNext: 'Зберегти та продовжити',
    clear: 'Очистити',
    find: 'Шукати',
    issue: 'Видати',
    close: 'Закрити',
    read: 'Зчитати',
    setSign: 'Підписати',
    yes: 'Так',
    no: 'Ні',
    confirm: 'Підтвердити',
    createStatement: 'Створити заяву',
    upload: 'Завантажити',
    addWatermark: 'Додати водяний знак',
    destroy: 'Видалити',
    viewDocuments: 'Переглянути документи на основі яких було видано документ',
    chooseAll: 'Вибрати усі',
    chooseNothing: 'Жодного',
    multiSign: 'Multi-підпис',
    closeMultiSign: 'Вийти з Multi-підпису',
    createReport: 'Сформувати звіт',
    downloadFile: 'Завантажити файл',
    'replacement-statement': 'Подати заяву на заміну РД',
    statusSpoiled: 'Змінити статус на Зіпсовано',
    go: 'Перейти',
    copy: 'Скопіювати',
    ok: 'Ok',
    next: 'Далі',
    goHome: 'На головну',
    install: 'Встановити',
    verify: 'Верифікувати',
    reject: 'Скасувати',
    generate: 'Згенерувати',
    continue: 'Продовжити',
    send: 'Відправити'
  },
  $vuetify: {
    badge: 'Значок',
    close: 'Закрити',
    dataIterator: {
      noResultsText: 'Відповідних записів не знайдено',
      loadingText: 'Завантаження елементів...',
    },
    dataTable: {
      itemsPerPageText: 'Рядки на сторінці:',
      ariaLabel: {
        sortDescending: 'Відсортовано за спаданням.',
        sortAscending: 'Відсортовано за зростанням.',
        sortNone: 'Не відсортовані.',
        activateNone: 'Активуйте, щоб прибрати сортування.',
        activateDescending: 'Активуйте, щоб сортувати за спаданням.',
        activateAscending: 'Активуйте, щоб сортувати за зростанням.',
      },
      sortBy: 'Сортувати за',
    },
    dataFooter: {
      itemsPerPageText: 'Елементів на сторінці:',
      itemsPerPageAll: 'Усі',
      nextPage: 'Наступна сторінка',
      prevPage: 'Попередня сторінка',
      firstPage: 'Перша сторінка',
      lastPage: 'Остання сторінка',
      pageText: '{0}-{1} of {2}',
    },
    datePicker: {
      itemsSelected: '{0} вибрано',
      nextMonthAriaLabel: 'Наступний місяць',
      nextYearAriaLabel: 'Наступний рік',
      prevMonthAriaLabel: 'Попередній місяць',
      prevYearAriaLabel: 'Попередній рік',
      dateStart: 'Дата початку',
      dateEnd: 'Дата кінця',
      datePeriod: 'Дата створення за період',
      dateMeetingPeriod: 'Дата події за період',
    },
    noDataText: 'Дані відсутні',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}',
      },
    },
    calendar: {
      moreEvents: '{0} більше',
    },
    fileInput: {
      counter: '{0} файли',
      counterSize: '{0} файлів ({1} загалом)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Навігація пагінацією',
        next: 'Наступна сторінка',
        previous: 'Попередня сторінка',
        page: 'Перейти на сторінку {0}',
        currentPage: 'Поточна сторінка, Сторінка {0}',
      },
    },
  },
  notify: {
    title: {
      info: 'Зверніть увагу!',
      success: 'Успіх',
      warning: 'Увага',
      error: 'Помилка',
    },
    errorGetKey: 'Помилка ключу доступу, перезавантажте сторінку',
    info: {
      is_diplom_on_sign:
        'Підтвердження буде підписано автоматично після підписання диплома. Перейти на диплом для підписання ?',
      'choose a row': 'Для використовування функції копіювання, виберіть рядок з таблиці',
    },
    success: {
      copy: 'Скопійовано успішно',
      active: "Користувача успішно активовано",
      deactive: "Користувача успішно деактивовано"
    },
    'Not found ': 'Інформації не знайдено',
    'You can not deactivate yourself ': 'Ви не можете деактивувати власний профіль',
    'Geoposition required': "Будь ласка, увімкніть вашу геолокацію, перш ніж продовжити",
    'Location error ': 'Доступ до сервісу обмежено у вашому регіоні',
    can_sign_auto: '{value} aвтопідпис документів',
    'Template for document does not exists': 'Шаблону для документа не існує',
    'date_end - cannot update record': 'Не можна відредагувати ',
    'date_start - cannot update record': 'Дата початку не може бути раніше чим завтра',
    'Duplicate has been already created': 'Дублікат уже створено',
    'Status document is not valid': 'Документ про статус недійсний',
    'Document is signed with cifra, or sailor`s name didnt change':
      'Документ підписаний цифрою, або прізвище моряка не змінилося',
    'Couldnt create duplicate': 'Не вдалося створити дублікат',
    'Sailor does not have email': 'У моряка не має веріфікованого email',
    'Valid sign is not found for the user': 'У користувача немає дійсного водяного знака підпису',
    'Profile does not exists': 'Профіль не існує',
    'Sailor has no full name in English': 'У моряка немає повного імені англійською',
    'NotAllowedError - The operation either timed out or was not allowed': 'Операція або минула, або не була дозволена',
    'Port Sevastopol has no captain!': 'У порту Севастополь немає капітана',
    'Image size must be 400x400 pixels!': 'Розмір зображення має бути 400x400 пікселів',
    'The submitted data was not a file  Check the encoding type on the form':
      'Надані дані не були файлом. Перевірте тип кодування на формі',
    'SecurityError - This is an invalid domain': 'Помилка безпеки – це недійсний домен',
    'SecurityError - The relying party ID is not a registrable domain suffix of, nor equal to the current domain':
      'Помилка безпеки – Ідентифікатор перевіряючої сторони не є суфіксом домену, який можна зареєструвати, і не дорівнює поточному домену',
    destroyWatermark: 'Водяний знак підпису було видалено',
    warningWatermark: 'Ви впевнені що хочете видалити водяний знак підпису',
    uploadedWatermark: 'Водяний знак завантажено',
    'No file was submitted': 'Жоден файл не відправлено',
    'Image size must be 400x400 pixels ': 'Розмір зображення має бути 400x400 пікселів',
    'Select a valid choice  That choice is not one of the available choices ':
      'Виберіть правильне значення. Цей значення не є одним із доступних',
    'Sailor does not exists': 'Моряка не існує',
    'Incorrect username or password': "Неправильне ім'я користувача або пароль",
    'Sailor not found': 'Моряка не знайдено',
    'User is blocked': 'Користувача заблоковано',
    'User does not found': 'Користувача не знайдено',
    'Please contact a administrator to add u2f': 'Зверніться до адміністратора, щоб додати u2f',
    'Info for this task not found': 'Інформація для цього завдання не знайдена',
    'Photo for document not found': 'Фото для документа не знайдено',
    'Document must be in Wait signature status': 'Документ має бути в статусі «Очікування підписання',
    'Select a valid choice That choice is not one of the available choices':
      'Виберіть правильний вибір. Цей вибір не є одним із доступних.',
    'Please send body with data': 'Тіло запиту не має бути пустим',
    'Status is wrong': 'Статус неправильний',
    'Mismatch of user type': 'Невідповідність типу користувача',
    'Status document is incorrect for education': 'Cтатус документа не відповідає освіті',
    'Invalid page': 'Недійсна сторінка',
    'Bad answer from service': 'Погана відповідь від сервіса',
    'You do not have permission to perform this action': 'Ви не маєте дозволу на виконання цієї дії',
    'User has no relation to the port': 'Користувач не має відношення до порту',
    'Related docs does not exists': 'Пов’язаних документів не існує',
    'Invalid token': 'Не валідний токін повторіть авторизацію',
    'sailor photo is not valid': 'У моряка немає валідного фото',
    'last_name and first_name is required': "Прізвище та Ім'я обов'язкові для пошуку моряка",
    'You do not have permission to perform this action ': 'У вас немає дозволу на виконання цієї дії',
    fileAlreadyUploaded: 'Файл {fileName} уже було додано',
    maxFilesCount: 'Додано максимально дозволену кількість файлів - {count}',
    exceedMaximumFileSize: 'Перевищено максимально дозволений розмір файлу',
    invalidFileType: 'Невірне розширення файлу',
    'Authentication credentials were not provided ': 'Облікові дані для автентифікації не були надані',
    'Cant create service record with delivery to morrichservice': 'Неможливо створити ПКМ з типом доставки моррічсервіс',
    'A user with that username already exists ': 'Користувач з такою поштою вже існує',
    'User profile with this tax number already exists ': 'Користувач з таким РНОКПП вже існує',
    'User profile with this unique demographic number already exists ': 'Користувач з таким УНЗР вже існує',
    'Please use your signature': 'Будь ласка, використовуйте свій підпис',
    'Profile not found': 'Профіль не знайдено',
    'diia reason with this sequence number already exists ': 'Причина відхилення з таким номером вже існує',
    'Document with this number already exists': 'Документ із таким номером уже існує',
    'Statement is not in draft': 'Заява вже знаходиться в обробці',
    'Failed to create request for selected options ': 'Створення заяви неможливе',
    'Library need extension': 'Виникла помилка при взаємодії з криптографічною бібліотекою. Веб-розширення для роботи бібліотеки web-підпису не запущено або не інстальовано в браузері. Для продовження необхідно запустити або інсталювати веб-розширення',
    'Library not installed': 'Виникла помилка при взаємодії з криптографічною бібліотекою. Додаток для роботи бібліотеки підпису не запущено або не інстальовано . Для продовження необхідно запустити або інсталювати додаток',
    'Instance is used': 'Видалення неможливе. Це значення вже використовується',
    'User with this type not found': 'Користувачів з таким типом облікового запису не знайдено або були заблоковані',
    'User not found': 'Користувача не знайдено',
    'Invalid signature': 'Недійсний підпис',
    'Unable to add document  Validity of confirmation is more than 6 months': 'Неможливо додати документ. Термін дії підтвердження більше ніж 6 місяців.',
    'Too many statements, limit to 600': 'Перевищено запит на формування звіту, скористайтесь фільтром. Максимально 600 записів',
    'secretary_sqc not exists': 'Не знайдено активних членів ДККМ',
    'No active members secretary_sqc': 'Не знайдено активних членів ДККМ',
    'Can not decode code param': 'Не вдається розшифрувати параметр коду',
    'Unable to login via id gov ua': 'Не вдається увійти через id.gov.ua',
    'Unable to get user data via id gov ua': 'Не вдається отримати дані користувача через id.gov.ua',
    'Unable to get user tax number via id gov ua': 'Не вдається отримати податковий номер користувача через id.gov.ua',
    saveUser: {
      success: 'Користувач створений',
      error: 'Помилка створення користувача'
    },
    editUser: {
      success: 'Дані користувача змінено',
      error: 'Помилка змінення даних користувача'
    },
    saveKey: {
      success: 'Ключ додано',
      error: 'Помилка додавання ключа'
    },
    assignedUser: {
      success: 'Відповідальну особу змінено',
      error: 'Помилка змінення відповідальної особи'
    },
    failedFetch: 'Запит завершився помилкою',
    notFound: 'Не знайдено дані по запиту',
    serverError: 'Помилка сервера. Будь ласка, зверніться до адміністрації сайту для вирішення проблеми.',
    addFinger: 'Прикладіть палець до ключу',
    statementChanged: {
      success: 'Заява змінена',
      error: 'Заява не змінена'
    },
    documentChanged: {
      success: 'Документ змінено',
      error: 'Документ не змінено'
    },
    signDocument: {
      success: 'Підписано успішно',
      error: 'При підписані виникла помилка',
    },
    signDocumentConfirmation: {
      success: 'Підтвердження диплома підписано успішно',
      error: 'При підписані підтвердження диплома виникла помилка'
    },
    paymentFileRequired: "Скан або фото оплати обов'язково",
    warningSailorEngName: 'У моряка немає повного імені англійською',
    validation: {
      required: 'Поле обов`язкове для заповнення',
      number_doc: 'Номер може містити цифри від 0 до 9, та символи .-/ ',
      value: 'Не вірне значення',
      length: 'Довжина поля не коректна',
      maxValue: 'Перевищено максимальне значення',
      minValue: 'Перевищено мінімальне значення',
      maxLength: 'Перевищено максимальну довжину',
      minLength: 'Перевищено мінімальну довжину',
      alphaUA: 'Використовуйте тільки українську',
      alphaEN: 'Використовуйте тільки латинські літери',
      email: 'Введіть корректний email',
      minStringLength: 'Мінімальна довжина: {min} символів',
      fileNameFormat: 'Використовуйте лише доступні символи',
      isWeekday: 'Неможливо обрати вихідний день'
    },
    downloadedDocument: 'Документ завантажено на пристрій',
    waitReportCreating: 'Очікуйте завершення формування звіту',
    createStatement: 'Заяву успішно створено',
    createdProtocol: 'Протокол був створений',
    requiredFullName: 'Необхідно ввести прізвище та імʼя',
    rejectedVerificationDocument: 'Заяву на верифікацію успішно відхилено',
    createdSailorStatement: 'Заява на реєстрацію моряка створена',
    createdDirectory: 'Довідник був оновлений',
    changedDirectory: 'Довідник був змінений',
    removedDirectory: 'Довідник був видалений',
    finishedWorkDocumentStatementProcess: 'Процес створення заяви отримання кваліфікаційного документа закінчено',
    changesDecisionAppealStatement: 'Заява на оскарження рішення була оновлена',
    createdDecisionAppealStatement: 'Заява на оскарження рішення була створена',
    deletedDecisionAppealStatement: 'Заява на оскарження рішення видалено',
    successfullyProceedStatement: 'Заяву прийнято в роботу',
    changedReviewInformation: 'Інформацію щодо розгляду заяви було змінено',
    useSignature: 'Використовуйте ключ файлового підпису',
    noData: 'Немає даних',
    createdProfessionalCompetenceStatement: 'Заява на проведення перевірки професійної компетентності була створена',
    deletedProfessionalCompetenceStatement: 'Заява на проведення перевірки професійної компетентності була видалена',
    updateProfessionalCompetenceStatement: 'Заява на проведення перевірки професійної компетентності була оновлена',
    createdInformationCorrectionStatement: 'Заява на виправлення відомостей у ДРДМ була створена',
    deletedInformationCorrectionStatement: 'Заява на виправлення відомостей у ДРДМ була видалена',
    updatedInformationCorrectionStatement: 'Заява на виправлення відомостей у ДРДМ була оновлена',
    noInformationByUniqueDemographicNumber: 'В системі немає данних по вказаному УНЗР',
    noInformationByTaxNumber: 'В системі немає данних по вказаному УНЗР',
    createdCertificateETI: 'Свідоцтво ЗПМ було створено',
    updatedCertificateETI: 'Свідоцтво ЗПМ було оновлено',
    deletedCertificateETI: 'Свідоцтво ЗПМ було видалено',
    deletedWorkDocumentStatement: 'Заяву на отримання кваліфікаційного документа видалено'
  },
  message: {
    "Can't convert document to PDF":
      'Невийшло згенерувати файл для підпису, спробуйте переглянути файл і підписати знову',
    'detail - Not found ': 'Деталі - документ не знайдено',
    'Sailor not found': 'Моряка не знайдено',
    'Diploma number is not assigned It is necessary to sign a diploma ':
      'Номер диплома не присвоюється. Необхідно підписати диплом.',
    'You are already signed this document': 'Ви вже підписали цей документ',
    'Photo for document not found': 'Фото для документа не знайдено',
    'Status document is not WAIT_SIGNATURE': "Документ не в статусі 'Очікує підписання'",
    'Can`t convert document to PDF': 'Невдалося конвертувати документ в PDF',
    'Signature or stamp not found': 'Підпис не знайдено',
    'Valid sign is not found for the user': 'У користувача дійсний підпис не знайдено',
    OK: 'Успіх',
    ERROR: 'Помилка',
    'Port Sevastopol has no captain!': 'У порту Севастополь немає капітана!!',
    'Sailor has no full name in English': 'У моряка немає повного імені англійською',
  },
  dashboard: {
    analytic: 'Аналітика',
    sailorCount: 'Кількість моряків',
    sailorLabel: 'Загальна кількість моряків у системі',
    newSailorLabel: 'Нових реєстрацій моряків в особистому кабінеті',
    serviceRecord: 'Видано послужних книжок моряка',
    qualificationDocuments: 'Видано кваліфікаційних документів моряка',
    statements: 'Подано заяв моряками через особистий кабінет',
    eti: 'Видано НТЗ сертифікатів',
    perDay: 'Денна аналітика',
    perWeek: 'Поточна неділя',
    perMonth: 'Місяць',
    perYear: 'Рік',
    allSailors: 'Всього моряків',
    allVerification: 'Всього документів, чинність яких можна перевірити',
  },
  noData: 'Дані відсутні',
  sailorName: 'Прізвище та ім`я моряка',
  sex: 'Стать',
  rank: 'Звання',
  position: 'Посада',
  phoneNumber: 'Номер телефону',
  qualificationDocuments: 'Кваліфікаційні документи',
  educationDocuments: 'Освітні документи',
  number: 'Номер',
  typeDocument: 'Тип документа',
  status: 'Статус',
  status_document: 'Статус документа',
  educationInstitution: 'Навчальний заклад',
  action: 'Дія',
  comment: 'Коментар',
  port: 'Порт',
  dateStart: 'Дата видачі',
  dateEnd: 'Дата закінчення',
  courseDateEnd: 'Дата закінчення курсу',
  strictBlank: 'Бланк суворої звітності',
  fullName: 'ПІБ',
  fullNameUkr: 'ПІБ українською',
  nameCompany: 'Організація',
  email: 'Email',
  typeUser: 'Тип користувача',
  addUser: 'Додати користувача',
  secretary_sqc: 'Серетар ДКК',
  registry: 'Реєстр',
  dpd: 'Серетар ДПВ',
  admin: 'Адміністратор',
  password: 'Пароль',
  lastName: 'Прізвище',
  firstName: 'Ім`я',
  lastNameEng: 'Прізвище англійською',
  firstNameEng: 'Ім`я англійською',
  fullNameEng: 'Ім\'я та прізвище англійською',
  signInAccount: 'Введіть свій email',
  limitation: 'Обмеження',
  hospital: 'Лікарня',
  doctor: 'Лікар',
  country: 'Країна',
  numberShip: 'Номер судна',
  nameShip: 'Назва судна',
  typeShip: 'Тип судна',
  modeOfNavigation: 'Режим плавання',
  portShipRegistration: 'Порт судна',
  shipOwner: 'Власник судна',
  grossCapacity: 'Валова місткість судна',
  propulsionPower: 'Потужність ГЕУ (кВт)',
  refrigeratingPower: 'Холодопродуктивність (кКал/год)',
  electricalPower: 'Потужність суднового електрообладнання (кВт)',
  typeGeu: 'Тип ГЕУ',
  levelRefrigerPlant: 'Кількість холодопродуктивних установок',
  tradingArea: 'Райони плавання',
  captainNameUkr: 'ПІБ капітана українською',
  captainFirstName: 'Ім\'я капітана',
  captainLastName: 'Прізвище капітана',
  captainNameEng: 'ПІБ капітана англійською',
  placeStart: 'Місце влаштування на судно',
  placeEnd: 'Місце звільнення із судна',
  datePlacement: 'Дата влаштування на судно',
  releaseDate: 'Дата звільнення із судна',
  units: {
    kWat: 'кВт',
    calHour: 'кКал/год',
    year: 'рік',
  },
  equipmentGmzlb: 'Апаратура ГМЗЛБ',
  bookRegistrationPractical: 'Книга реєстрації практичної підготовки',
  isRepaired: 'Чи було судно виведено з експлуатації',
  daysRepair: 'Всього днів',
  datePickerStart: 'Дата початку',
  datePickerEnd: 'Дата кінця',
  responsibility: 'Обов`язки',
  serial: 'Серія',
  registryNumber: 'Реєстраційний номер',
  educationForm: 'Форма освіти',
  university: 'ВНЗ',
  qualification: 'Кваліфікація',
  speciality: 'Спеціальність',
  specialityNameUkr: 'Спеціальність українською',
  specialityNameEng: 'Спеціальність англійською',
  dateIssue: 'Дата видачі',
  expiredDate: 'Дата припинення дії',
  isDuplicate: 'Дублікат',
  yearEnd: 'Рік закінчення',
  diploma: 'Диплом',
  recordType: 'Тип запису стажу',
  workBook: 'ПКМ',
  numberPageBook: 'Номер сторінки у ПКМ',
  daysWork: 'Загальна кількість діб',
  placeWork: 'Місце роботи або проходження практики',
  allResponsibility: 'Обов`язки на судні',
  dateFrom: 'Дата початку',
  dateTo: 'Дата зкінчення',
  created: 'Дата створення',
  start: 'Дата початку дії',
  dateMeeting: 'Дата події',
  isPayed: 'Статус оплати',
  isPayedBlank: 'Статус оплати бланку',
  delivery: 'Доставка',
  dateStartServiceRecord: 'Дата початку дії ПКМ',
  authorizedPerson: 'Уповноважена особа',
  authorizedPersonUkr: 'Уповноважена особа (українською)',
  authorizedPersonEng: 'Уповноважена особа (англійською)',
  waibillNumber: 'Номер ТТН',
  etiDocuments: 'Свідоцтва ЗПМ',
  type: 'Тип',
  branchOffice: 'Філія',
  middleName: 'По батькові',
  region: 'Регіон',
  city: 'Місто',
  sailorNotFound: 'Нам не вдалося знайти моряка. Переконайтеся, що ви ввели правильні дані.',
  changeActive: 'Змінити активність',
  eti: 'НТЗ',
  course: 'Курс',
  addSignature: 'Додати ключ',
  documentSigning: 'Підписання документа',
  key: 'Ключ',
  edrpou: 'ЄДРПОУ',
  drfo: 'ДРФО',
  typeKey: 'Тип ключа',
  issuedBy: ' Ким видано',
  statuses: {
    expired: 'Закінчився термін дії',
    valid: 'Дійсний',
    approve: 'Схвалити',
    reject: 'Відхилити',
    spoiled: 'Зіпсований',
    canceled: 'Скасований',
    destroyed: 'Знищений',
    invalid: 'Недійсний',
    lost: 'Втрачений',
    suspended: 'Дію призупинено',
    processing: 'В обробці',
    verification: 'Верифікація',
  },
  typeDigitization: {
    clientPassport: 'Паспорт моряка',
    passport: 'Паспорт',
    foreignPassport: 'Закордонний паспорт',
    education: 'Освітні',
    qualification: 'Кваліфікаційні',
    proof: 'Підтвердження диплома',
    certificateOfNavigator: 'Посвидчення судноводия',
    certificateETI: 'Свідоцтво ЗПМ',
    serviceRecord: 'Послужна книжка',
    protocolSqc: 'Протокол',
    medical: 'Медицина',
    experienceInServiceRecord: 'Запис до ПКМ',
    experience: 'Стаж',
  },
  proof: 'Підтвердження робочого диплома',
  qualificationDocument: 'Кваліфікаційний документ',
  payed: 'Оплачено',
  notPayed: 'Не оплачено',
  isConfirmed: 'Підтверджений стаж',
  recordBook: 'Записи ПКМ',
  experienceDocs: 'Довідки про стаж',
  yes: 'Так',
  no: 'Ні',
  ces_exam: 'Іспит CES 6.0',
  cancelStatementServiceRecord: 'Ви впевнені, що хочете відхилити заяву',
  reason: 'Причина',
  sailorAdmittedToSQCExam: {
    title: 'Моряків допущено до іспиту ДКК',
    ces_exam_count: 'Заяви на іспити CES',
    sqc_exam_count: 'Заяви на Іспити ДКК',
    all_statement_count: 'Всього заяв',
    serialNameNumber: 'Кількість',
  },
  submittedSQC: {
    title: 'Подані заяви ДКК',
    ces_exam_count: 'Іспити CES',
    sqc_exam_count: 'Іспити ДКК',
    serialNameNumber: 'Кількість',
    no_exam_count: 'Підтвердження стажу, без іспиту',
    all_statement_count: 'Всього заяв',
  },
  fact: {
    title: 'Протоколи ДКК факт',
    ces_exam_count: 'Протоколи іспити CES',
    sqc_exam_count: 'Протоколи іспити ДКК',
    no_exam_count: 'Протоколи підтвердження стажу ДКК',
    all_statement_count: 'Всього протоколів',
    done: 'Здано',
    notPassed: 'Не здано',
    total: 'Всього',
  },
  ces_exam_without_protocol_count: 'CES іспити без протоколу',
  ces_exam_with_protocol_count: 'CES іспити з протоколом',
  sqc_exam_without_protocol_count: 'ДКК іспити без протоколу',
  sqc_exam_with_protocol_count: 'ДКК іспити з протоколом',
  no_exam_with_protocol_count: 'Підтвердження за стажем з протоколом',
  no_exam_without_protocol_count: 'Підтвердження за стажем без протоколу',
  educationExtent: 'Ступінь вищої освіти',
  addCopyDocs: 'Додати копію документа',
  dateCreatedAt: 'Дата видачі',
  dateEndEducation: 'Дата закінченя навчання',
  dateIssueDocument: 'Дата видачі документа',
  copyDoc: 'Копії документа',
  decision: 'Рішення',
  protocolWasIssued: 'Документи на підставі яких був виданий протокол',
  type_doc: 'Тип документа',
  unsignedReason: 'Причина відмови підписання',
  date_start: 'Дата початку дії',
  name_issued: 'Ким виданий',
  isPayedStatement: 'Статус оплати заяви',
  protocol_dkk: 'Протокол ДКК',
  sailor: 'ПІБ моряка',
  signatureUploading: 'Додання персонального підпису',
  noDataTextDPD: 'Нажаль у моряка не має протоколу для створення заяви на це звання',
  noDataTextSearchSailor: 'Введіть прізвище та імʼя моряка для пошуку',
  typeDelivery: 'Тип доставки',
  area: 'Область',
  warehouse: 'Відділення',
  street: 'Вулиця',
  house: 'Дім',
  apartment: 'Квартира',
  from_date: 'З дати',
  to_date: 'По дату',
  DocumentsView: 'Документи на основі яких було видано документ',
  multisign: {
    title: 'Підписання документів',
    docs_sign: 'Документів підписано',
    with: 'з',
    docs_after_sign: 'Документи які пройшли підписання',
  },
  profile: {
    createSignature: 'Створити підпис',
    uploadPNG: 'Завантажити PNG',
  },
  clientProfile: 'Профіль',
  platonPayment: 'ID транзакції - оплата через сервіс Platon',
  manualPayment: 'ID транзакції - оплата за реквізитами',
  createdAtPayment: 'Час транзакції',
  amount: 'Сума транзакції',
  issue: 'Ким видано',
  deliveryMRS: 'Самовивіз із Моррічсервісу',
  passportInfo: 'Паспортні дані / РНОКПП',
  typePayment: 'Тип оплати',
  idPayment: 'ID транзакції',
  platon: 'Оплата через сервіс Platon',
  manual: 'Оплата за реквізитами',
  dateForFilter: {
    date_birth_full_title: 'Дата народження (дата/період)',
    date_birth: 'Дата народження',
    from_date_birth: 'Дата народження з',
    to_date_birth: 'Дата народження по',
    date_start_full_title: 'Дата видачі (дата/період)',
    date_start: 'Дата видачі',
    from_date_start: 'Дата видачі з',
    to_date_start: 'Дата видачі по',
    date_meeting: 'Дата події',
    from_date_meeting: 'З дати події',
    to_date_meeting: 'По дату події',
    from_date: 'Дата створення з',
    to_date: 'Дата створення по',
    date_create_full_title: 'Дата створення (дата/період)',
    date_create: 'Дата створення',
    date_issue: 'Дата видачі',
    from_date_issue: 'Дата видачі з',
    to_date_issue: 'Дата видачі по',
    review_deadline: 'Крайній термін розгляду заяви',
    review_deadline_full_title: 'Крайній термін розгляду заяви (дата/період)',
    from_review_deadline: 'Крайній термін розгляду заяви з',
    to_review_deadline: 'Крайній термін розгляду заяви по',
    exam_date_full_title: 'Дата проведення іспиту (дата/період)',
    exam_date: 'Дата проведення іспиту',
    from_exam_date: 'Дата проведення іспиту з',
    to_exam_date: 'Дата проведення іспиту по',
    date_end_full_title: 'Дата припинення дії (дата/період)',
    date_end: 'Дата припинення дії',
    from_date_end: 'Дата припинення дії з',
    to_date_end: 'Дата припинення дії по'
  },
  youStillHere: 'Ви ще тут?',
  dateBirth: 'Дата народження',
  number_page_book: 'Номер сторінки в ПКМ',
  status_line: 'Статус запису в ПКМ',
  copy: 'Копії',
  totalDocs: 'Всього документів',
  support: 'Служба підтримки',
  'loading-text': 'Завантаження...',
  'no-results-text': 'Дані не знайдено',
  'no-data': 'Таблиця пуста. Записів немає.',
  proofDocuments: 'Підтвердження дипломів',
  chatWood: {
    launcherTitle: 'Виникли питання? Із задоволенням допоможемо',
  },
  documentSailor: 'Документ моряка',
  'Do you want replace document': 'Ви бажаєте замінити документ',
  can_auto_sign: 'Автоматичне підписання документів',
  can_sign_true: 'Дозволенно',
  can_sign_false: 'Забороненно',
  'I give my consent to the use of automatic signing of the document package': 'Я надаю свою згоду на використання автоматичного підписання пакета документів',
  'Do not give consent': 'Не надати згоду',
  'Give consent': 'Надати згоду',
  'Signature expiration date end': 'Термін дії підпису закінчиться о {date_end}',
  textConfirmAction: 'Ви впевнені що хочете {value}?',
  changeStatus: 'Змінити статус',
  operationServiceLimited: ' роботу сервісу обмежено.',
  signQualificationDocBlock: 'Підпис кваліфікацийних документів буде недоступний!',
  detailInfo: {
    statement: {
      SRB: 'Детальна інформація про заяву',
      SQC: 'Детальна інформація про заяву № {number}',
    },
  },
  tooltip: {
    info: 'Детальна інформація',
    edit: 'Редагування',
    active: "Активувати",
    deactive: "Деактивувати"
  },
  cesExam: 'CES іспит',
  transactionId: 'ID транзакції',
  datePayed: 'Дата оплати',
  taxNumber: 'РНОКПП',
  purpose: 'Призначення платежа',
  passportData: 'Паспортні дані',
  referenceState: 'Стан платежу',
  'If you close the form, the data will be lose, continue ?': 'Якщо Ви закриєте форму введені дані будуть втрачені, продовжити ? ',
  idStatement: 'ID заяви',
  typeStatement: 'Тип заяви',
  editTransaction: 'Редагування транзакції ID({idTransaction})',
  settlementDate: 'Дата зарахування платежу',
  create: 'Створити',
  exit: 'Вийти',
  listTypeQualDocs: 'Тип кваліфікаційних документів',
  authorization: 'Авторизація',
  enterLoginAndPass: 'Введіть свій логін та пароль',
  signIn: 'Авторизуватись',
  mainData: 'Основні дані',
  appendServiceRecordBook: 'Видати послужну книгу',
  editServiceRecordBook: 'Редагувати послужну книгу',
  setOtherKey: 'Обрати інший ключ',
  tryAgain: 'Не вдалося виконати дію, спробуйте ще раз',
  withoutStamp: 'Підписати без печатки',
  changePaymentStatus: 'Змінити статус оплати',
  oldPattern: 'Паспорт старого зразка',
  idPassport: 'ID картка',
  sailors: 'Моряки',
  serviceRecordStatement: 'Заява на отримання послужної книги',
  rejectServiceRecordStatement: 'Скасувати заяву на отримання послужної книги',
  rejectSeafarerPassportStatement: 'Скасувати заяву на отримання паспорта моряка',
  rejectAccountVerification: 'Скасувати заяву на верифікацію облікового запису',
  rejectQualificationStatement: 'Скасувати заяву на отримання робочого документа',
  rejectSqcStatement: 'Скасувати заяву на проходження ДКК',
  rejectEducationVerification: 'Скасувати заяву на верифікацію освітнього документа',
  rejectExperienceDocumentVerification: 'Скасувати заяву на верифікацію документа про стаж',
  rejectMedicalDocumentVerification: 'Скасувати заяву на верифікацію медичного сертифіката',
  rejectServiceRecordBookVerification: 'Скасувати заяву на верифікацію ПКМ',
  rejectCivilPassportVerification: 'Скасувати заяву на верифікацію паспорту громадянина',
  rejectForeignPassportVerification: 'Скасувати заяву на верифікацію закордонного паспорту',
  rejectSailorPassportVerification: 'Скасувати заяву на верифікацію ПОМ',
  rejectQualificationDocumentVerification: 'Скасувати заяву на верифікацію кваліфікаційного документа',
  rejectWorkDocumentStatement: 'Скасувати заяву на отримання кваліфікаційного документа',
  dateRenewal: 'Строк дії посвідчення продовжено до',
  captain: 'Капітан',
  pageNumber: 'Номер сторінки №{page}',
  recordsFound: 'Записів знайдено',
  lastNameUkr: 'Прізвище українською',
  firstNameUkr: 'Ім`я українською',
  middleNameUkr: 'По батькові українською',
  dateStudyStart: 'Дата початку навчання',
  dateStudyTermination: 'Дата припинення навчання',
  profession: 'Професія',
  novaposhta: 'Нова Пошта',
  novaposhtaCourier: 'Нова Пошта (кур\'єр)',
  selfPickupFrom: 'Самовивіз з',
  periodFrom: 'Період з',
  periodTo: 'Період по',
  rejectReason: 'Причина відхилення',
  rejectedBy: 'Ким відхилено',
  rejectDate: 'Дата відхилення',
  createProtocol: 'Створити протокол',
  commissionHead: 'Голова комісії',
  commissionDeputyHead: 'Заступник Голови комісії',
  commissionSecretary: 'Секретар комісії',
  commissionMember: 'Член комісії',
  commissionerFullName: 'ПІБ члена комісії',
  commissionerSecretaryFullName: 'ПІБ секретаря комісії',
  commissionerHeadFullName: 'ПІБ голови комісії',
  oops: 'Упс! Трапилась несподіванка!',
  pageNotExist: 'Вибачте, такої сторінки не існує, або була допущена помилка при вводі адреси сайту.',
  sailorCabinetRegistration: 'Реєстрація в кабінеті моряка',
  today: 'Сьогодні',
  duringWeek: 'За тиждень',
  duringMonth: 'За місяць',
  duringYear: 'За рік',
  issuedServiceRecordBooksCount: 'Кількість виданих ПКМ',
  issuedQualificationDocumentsCount: 'Кількість виданих кваліфікаційних документів',
  issuedSeafarerPassportsCount: 'Кількість виданих посвідчень особ моряка',
  issuedCertificatesETICount: 'Кількість виданих сертифікатів НТЗ',
  documentIssuing: 'Видача документа',
  servicePaymentReceipt: 'Квитанція про сплату послуги',
  receivingTerm: 'Період отримання',
  renewedDocumentNumber: 'Номер документа, що продовжується',
  citizenship: 'Громадянство',
  placeRegistration: 'Місце реєстрації',
  index: 'Індекс',
  flat: 'Квартира',
  placeLiving: 'Фактичне місце проживання',
  photoEditingBeforeVerification: 'Редагування фото перед верифікацією',
  missingDocuments: 'Документи, яких не вистачає',
  missingExperience: 'Не вистачає стажу',
  name: 'Назва',
  regulatoryRequirements: 'Нормативні вимоги',
  experience: 'Стаж',
  createDocument: 'Створити документ',
  enterVisitationReason: 'Вкажіть причину перегляду профілю',
  documents: 'Документи',
  statements: 'Заяви',
  fileKey: 'Файловий носій',
  tokenKeyKey: 'Захищений носій',
  user: 'Користувач',
  sqcExams: 'Екзамени ДКК',
  failed: 'Не сдано',
  passed: 'Сдано',
  total: 'Всього',
  uniqueRegistryNumber: 'УНЗР',
  search: 'Пошук',
  addDocument: 'Додати документ',
  documentsOnVerification: 'Документи на верифікації',
  taxNumberFiles: 'Сканкопії РНОКПП',
  processingDataFiles: 'Сканкопії договору та згоди на обробку персональних даних',
  medicalInstitution: 'Медичний заклад',
  examForm: 'Форма іспиту',
  level: 'Рівень',
  examType: 'Тип іспиту',
  authWithRole: 'Авторизуватися під роллю',
  makeDecision: 'Винести рішення',
  changeDecision: 'Змінити рішення',
  makeWorkDocumentDecision: 'Винести рішення щодо видачі кваліфікаційного документа',
  sqcHeadDecision: 'Рішення голови ДККМ',
  sqcMemberDecision: 'Рішення члена ДККМ',
  documentCopy: 'Скан-копія документа',
  role: 'Роль',
  userEditing: 'Редагування облікового запису',
  sendToPrint: 'Відправити на друк',
  generatedDocument: 'Згенерований документ',
  approveDecision: 'Схвалити рішення',
  sendToWorkDocumentRevision: 'Відправити на доопрацювання',
  sqcHeadComment: 'Коментар голови ДККМ',
  nameUkr: 'Назва українською',
  nameEng: 'Назва англійською',
  directoryDetails: 'Детальна інформація за довідником',
  id: 'ID',
  revisedStatement: 'Доопрацьована заява',
  description: 'Опис',
  rulesClause: 'Пункт правил',
  generateStatementBlank: 'Згенерувати бланк заяви',
  generateDecisionBlank: 'Згенерувати бланк рішення',
  visuallyImpairedVersion: 'Версія для людей з вадами зору',
  educationDocumentsData: 'Дані документа(-ів) про освіту, виданого починаючи з 2000 року',
  workDocumentStatementNumber: 'Бланк заяви №{number}',
  numberPage: 'Номер сторінки',
  blankSerial: 'Серія бланка',
  blankNumber: 'Номер бланка',
  authorizedPersonPositionUkr: 'Посада уповноваженої особи (українською)',
  authorizedPersonPositionEng: 'Посада уповноваженої особи (англійською)',
  receivedDocuments: 'Отримані документи',
  maritimeEducation: 'Морська освіта',
  diplomaPrintAndIssue: 'Роздрукувати та видати Диплом',
  endorsementPrintAndIssue: 'Роздрукувати та видати Підтвердження',
  userStatus: 'Статус користувача',
  chooseDirectoryType: 'Оберіть тип довідника',
  chooseDocumentType: 'Оберіть тип документа',
  chooseLimitation: 'Виберіть обмеження',
  positionFunctionLimitations: 'Обмеження за функціями',
  limitationFunction: 'Функція обмежень',
  positionLimitations: 'Обмеження за посадою',
  limitations: 'Обмеження',
  typeDirectory: 'Тип довідника',
  limitationNameUkr: 'Обмеження українською',
  limitationNameEng: 'Обмеження англійською',
  levelNameUkr: 'Рівень українською',
  levelNameEng: 'Рівень англійською',
  pleaseFillForm: 'Будь ласка, заповніть форму',
  fullTitle: 'Повна назва',
  shortTitle: 'Коротка назва',
  serialAndNumber: 'Серія та номер',
  step: 'Крок {step}',
  applicantInformation: 'Інформація про заявника',
  statementInformation: 'Інформація про заяву',
  identityDocument: 'Посвідчення особи моряка або інший документ, що посвідчує особу та підтверджує громадянство',
  sqcProtocolFullTitle: 'Сертифікат про успішне складання комп\'ютерного тесту CES 6.0',
  isRequired: 'Є обовʼязковим',
  familiarizeDocumentContent: 'Ознайомтесь зі змістом документа, якщо все вірно - перед підписанням його необхідно роздрукувати!',
  familiarizeDocumentConfirmation: 'Своєю дією ви підтверджуєте, що документ був вже роздрукованій та готовий до видачі!',
  payAttention: 'Зверніть увагу!',
  statementDocuments: 'Документи, що додаються до заяви',
  considerationDaysLeft: 'Залишилось днів на розгляд',
  addNewRecord: 'Додати новий запис',
  addNewExperienceRecord: 'Додати новий запис про стаж',
  addNewExperienceDocument: 'Додати новий документ про стаж',
  addNewLimitation: 'Додати нове обмеження',
  statementReviewDeadline: 'Крайній термін розгляду заяви',
  appealExplanation: 'Пояснення щодо оскарження',
  statement: 'Заява',
  makeAppealStatementDecision: 'Винести рішення щодо видачі оскарження',
  returnedDueToAppeal: 'Повернули через апеляцію',
  address: 'Адреса',
  appointment: 'Призначення',
  institutionNameUkr: 'Навчальний заклад українською',
  institutionNameEng: 'Навчальний заклад англійською',
  vesselPortRegistration: 'Порт приписки судна',
  vesselOwner: 'Судновласник',
  vesselOfficialNumber: 'Офіційний номер судна',
  vesselPosition: 'Посада на судні',
  portsOfCall: 'Порти заходження',
  fillingData: 'Дата заповнення',
  rankAndQualification: 'Звання / Тип свідоцтва',
  createdByMorrichservice: 'Створено ДП "Моррічсервіс"',
  skipStep: 'Пропустити крок',
  unfinishedProcessConformation: 'Процес створення заяви буде збережений в чернетці, але не був повністю завершений. Ви впевнені, що хочете залишити форму створення заяви?',
  leavePageConfirmation: 'Ви впевнені, що хочете залишити форму створення заяви?',
  leavePage: 'Залишити сторінку',
  continueCreating: 'Продовжити створення',
  assignedUser: 'Відповідальна особа',
  changeAssignedUser: 'Заміна відповідальної особи',
  existStatus: 'Статус наявності',
  active: 'Присутній',
  tempNotActive: 'Відсутній',
  welcome: 'Вітаємо!',
  forStartWorkChoosePointSideMenu: 'Для початку роботи - оберіть пункт з бокового меню',
  decisionNumber: 'Номер рішення',
  decisionDate: 'Дата винесення рішення',
  viewDecision: 'Переглянути рішення',
  downloadArchiveWithDocumentAndSign: 'Завантажити архів з документа та підписів',
  proceedStatementConfirmation: 'Ви впевнені, що хочете надіслати заяву на розгляд?',
  presenceType: 'Тип присутності',
  assignedReviewDate: 'Призначена дата розгляду',
  changeAssignReviewDate: 'Змінити призначену дату розгляду',
  exceedReviewDate: 'Призначена дата перевищує гранічний термін, що зазначений в п. 117 Поставнови 1499 від 30 грудня 2022 р.',
  meetingAddress: 'Адреса проведення засідання',
  changeAssignReviewPlace: 'Змінити адресу проведення засідання',
  communicationMeans: 'Засіб зв\'язку',
  changeCommunicationMeans: 'Змінити засіб зв\'язку',
  meetingPostponedReason: 'Причина перенесення засідання',
  changeMeetingInformation: 'Змінити інформацію щодо розгляду заяви',
  postponedMeetingDateHistory: 'Історія перенесення дати розгляду',
  appealCommissionMembers: 'Члени апеляційної комісії',
  operation: 'Операція',
  chooseKeyAndEnterPass: 'Оберіть ключ та введіть пароль',
  whoMakeChanges: 'Ким внесено зміни',
  changesMade: 'Внесені зміни',
  changesDescription: 'Значення "{value}" було змінено з "{pre}" на "{post}"',
  audit: {
    id: 'ID',
    number: 'Номер',
    number_document: 'Номер',
    tax_number: 'РНОКПП',
    unique_demographic_number: 'УНЗР',
    username: 'E-mail',
    last_name: 'Прізвище',
    last_name_eng: 'Прізвище (англійською)',
    first_name: 'Імʼя',
    first_name_eng: 'Імʼя (англійською)',
    middle_name: 'По батькові',
    type_user: 'Роль',
    type_statement: 'Тип заяви',
    status: 'Статус',
    status_document: 'Статус',
    is_disable: 'Статус',
    is_active: 'Статус активності',
    name_ukr: 'Назва українською',
    name_eng: 'Назва англійською',
    description: 'Опис',
    description_ukr: 'Опис',
    purpose: 'Призначення',
    appeal_explanation: 'Пояснення щодо оскарження',
    sequence_number: 'Пункт правил',
    review_deadline: 'Крайній термін розгляду заяви',
    review_at: 'Призначена дата розгляду',
    review_place: 'Адреса проведення засідання',
    diia_branch_office: 'Філія',
    diia_rank: 'Звання',
    diia_list_position: 'Посада',
    diia_decision: 'Рішення',
    diia_type_certificate: 'Кваліфікація',
    diia_type_document: 'Тип документа',
    presence_type: 'Тип присутності',
    postponement_reason: 'Причина перенесення засідання',
    communication_tool: 'Засіб зв\'язку',
    date_start: 'Дата початку дії',
    date_end: 'Дата припинення дії',
    exam_date: 'Дата проведення іспиту',
    course_date_end: 'Дата закінчення курсу',
    letter_strict_blank: 'Серія бланку суворої звітності',
    number_strict_blank: 'Номер бланку суворої звітності',
    inspection_purpose: 'Мета проведення перевірки',
    diia_eti_course: 'Курс',
    eti_institution: 'Навчальний заклад',
    changes: 'Які зміни треба внести',
    reason: 'Причина',
    reject_reason: 'Причина відхилення',
    name: 'Назва',
    head_name: 'Голова комісії з проведення медичного огляду моряків',
    member_names: 'Заступник(-и) голови з проведення медичного огляду моряків'
  },
  changesMadeHistory: 'Історія внесених змін',
  installLibrary: 'Встановити пакет бібліотеки підпису',
  waitForSign: 'Очікує підписання',
  signed: 'Підписано',
  statementNumber: 'Номер заяви',
  decisionChangingReason: 'Причина зміни рішення',
  postponedDecisionHistory: 'Історія змінення рішень',
  changeDate: 'Дата змінення',
  educationalLevel: 'Освітній рівень',
  experienceRecords: 'Записи про стаж',
  additionalRecordInformation: 'Додаткова інформація запису',
  serviceRecordOrRegistrationsBooks: 'Послужні книги моряка або книги реестрації підготовки',
  checkFullNameDeclaration: 'Будь ласка, уважно перевірте правильність відмінювання ПІБ. Якщо є помилка, Ви можете її виправити вручну змінивши текст в відповідному полі.',
  inGenitive: 'В родовому відмінку (кого?)',
  inDative: 'В давальному відмінку (кому?)',
  editClientInformation: 'Редагувати інформацію про заявника',
  examinationDate: 'Дата проведення іспиту',
  inspectionPurpose: 'Мета проведення перевірки',
  identityDocumentInformation: 'Інформація про документ, що посвідчує особу',
  deleteConfirmation: 'Ви впевнені, що хочете видалити заявку? Зворотний процес буде неможливим.',
  checkQes: 'Підтверждення дії з ЕЦП',
  createdBy: 'Ким створено',
  setResults: 'Виставити результати',
  endorsementByExperience: 'Підтвердження по стажу',
  documentNumber: 'Номер документа',
  changesShouldBeMade: 'Які зміни треба внести',
  noDocumentsByNumberFound: 'Документів за вказаним номером не знайдено',
  chooseCorrectionFile: 'Оберіть документ для виправлення',
  autoEndorsementLinking: 'Якщо заява створюється на виправлення професійного диплома або сертифіката компетентності, підтвердження прикріпляється автоматично за наявності',
  createAndDownloadPdf: 'Сформувати та завантажити PDF',
  signedBy: 'Ким підписано',
  availableSerialAndNumber: 'Номер та серія (серія за наявності)',
  downloadStatement: 'Завантажити заяву',
  downloadExamResult: 'Завантажити результат іспиту',
  documentName: 'Назва документа',
  createAndDownloadReport: 'Сформувати та завантажити звіт',
  checkClientInformation: 'Уважно перевірте персональні дані моряка. Якщо потрібно внести зміни, ви можете відредагувати інформацію.',
  enterReportName: 'Введіть назву звіту',
  reportCreateDescription: 'Зверніть увагу, звіт буде формуватися за заданими параметрами фільтрації та сортування за якийсь час. Після завершення формування звіт буде завантажено на ваш пристрій.',
  uploadProfessionalCompetenceCheck: 'Завантажте скан копію документа (протокол або відомість), який підтверджує проходження перевірки професійної компетенції',
  uploadFilledAppealDecisionStatement: 'Завантажте скан копію заповненої моряком друкованої заяви на оскарження рішень Адміністрації судноплавства',
  uploadDecisionCopyForSign: 'Завантажте скан копію рішення, яку буде підписано членами апеляційної комісії',
  uploadRejectFile: 'Завантажте скан копію документа про негативне рішення розгляду заяви',
  documentInformation: 'Інформація за документом',
  receivingDocumentBranchOffice: 'Філія отримання документа',
  sailorTrainingInstitution: 'Заклад підготовки моряків',
  legislation: 'Структурна одиниця Конвенції ПДНВ, Кодексу ПДНВ, чинне законодавство',
  accreditationTerm: 'Строк акредитації',
  addNewTrainingDirection: 'Додати новий напрям підготовки',
  trainingDirection: 'Напрям підготовки',
  trainingDirections: 'Напрями підготовки',
  absentUniqueDemographicNumber: 'УНЗР відсутній',
  comments: 'Коментарі',
  rejection: 'Скасування',
  revision: 'Відправлення на доопрацювання',
  revisionBy: 'Ким відправлено',
  revisionDate: 'Коли відправлено',
  notSpecified: 'Не вказано',
  privateStaff: 'Рядовий склад',
  officerStaff: 'Офіцерський склад',
  totalCommissionMembers: 'Всього членів комісії',
  presentCommissionMembers: 'Присутніх членів на засіданні',
  meetingDate: 'Дата засідання',
  meetingTimeStart: 'Час початку засідання',
  meetingTimeEnd: 'Час закінчення засідання',
  listenFor: 'Слухали',
  votedFor: 'Голосували за',
  votedAgainst: 'Голосували проти',
  agreeConclusionAddition: 'Таким чином Апеляційна комісія погоджується з висновком Державної кваліфікаційної комісії моряків...',
  firstOptionAgreeConclusionAddition: 'Враховуючи викладене, керуючись підпунктом першим пункту 109 Положення, Апеляційна комісія погоджується з висновком Державної кваліфікаційної комісії моряків...',
  useGenitiveCommissionersFullName: 'Вказуйте ПІБ учасників комісії у родовому відмінку. Ця інформація братиме участь у генерації бланка рішення апеляційної комісії.',
  downloadStatementWithSignature: 'Завантажити заяву з підписом',
  printedApplicationForm: 'Друкована форма бланка заяви',
  decisionRenderQuorum: 'Кворум щодо винесення рішення',
  assignDate: 'Дата призначення',
  isAssignedMember: 'Є відповідальним членом комісії',
  waitForDecision: 'Очікує рішення',
  makingDecision: 'Винесення рішення',
  meeting: 'Засідання',
  continueSentenceStatement: 'Продовжте твердження речення вказаного в назві поля',
  addNewCommissionMember: 'Додати нового члена комісії',
  typeCertificate: 'Тип свідоцтва',
  healthcareFacilityName: 'Назва закладу охорони здоров\'я',
  resolutionDateStart: 'Дата початку постанови',
  resolutionDateEnd: 'Дата закінчення постанови',
  sailorMedicalExaminationChairman: 'Голова комісії з проведення медичного огляду моряків',
  sailorMedicalExaminationChairmanDeputy: 'Заступник голови з проведення медичного огляду моряків',
  sailorMedicalExaminationChairmanDeputies: 'Заступник(-и) голови з проведення медичного огляду моряків',
  addNewDeputy: 'Додати нового заступника',
  authorizationWith: 'Авторизуватись з'
}
