const apiV1 = 'api/v1'
// const apiV2 = 'api/v2'

export const directories = [
  { endpoint: `${apiV1}/directories/sex/`, state: 'sex' },
  { endpoint: `${apiV1}/directories/status_document/`, state: 'statusDocument' },
  { endpoint: `${apiV1}/directories/country/`, state: 'country' },
  { endpoint: `${apiV1}/directories/eti/`, state: 'eti' },
  { endpoint: `${apiV1}/directories/diia/ranks/`, state: 'diiaRanks' },
  { endpoint: `${apiV1}/directories/diia/branch_offices/`, state: 'diiaBranchOffices' },
  { endpoint: `${apiV1}/directories/diia/educational_levels/`, state: 'diiaEducationLevels' },
  { endpoint: `${apiV1}/directories/diia/educational_institutions/`, state: 'diiaEducationInstitutions' },
  { endpoint: `${apiV1}/directories/diia/reasons/`, state: 'diiaReasons' },
  { endpoint: `${apiV1}/directories/diia/positions/`, state: 'diiaPositions' },
  { endpoint: `${apiV1}/directories/diia/type_certificates/`, state: 'diiaTypeCertificates' },
  { endpoint: `${apiV1}/directories/diia/functions/`, state: 'diiaFunctions' },
  { endpoint: `${apiV1}/directories/diia/levels/`, state: 'diiaLevels' },
  { endpoint: `${apiV1}/directories/diia/limitations/`, state: 'diiaLimitations' },
  { endpoint: `${apiV1}/directories/diia/functions_and_levels_for_position/`, state: 'diiaPositionFunctionsAndLevels' },
  { endpoint: `${apiV1}/directories/diia/limitations_for_function/`, state: 'diiaFunctionLimitations' },
  { endpoint: `${apiV1}/directories/diia/limitations_for_position/`, state: 'diiaPositionLimitations' },
  { endpoint: `${apiV1}/directories/diia/vessel_type/`, state: 'diiaVesselTypes' },
  { endpoint: `${apiV1}/directories/diia/educational_specialities/`, state: 'diiaEducationalSpecialities' },
  { endpoint: `${apiV1}/directories/diia/educational_type_documents/`, state: 'diiaEducationalTypeDocuments' },
  { endpoint: `${apiV1}/directories/diia/eti_courses/`, state: 'diiaEtiCourses' },
  { endpoint: `${apiV1}/directories/diia/sti/`, state: 'diiaSti' },
  // { endpoint: `${apiV1}/directories/diia/medical_institutions/`, state: 'diiaMedicalInstitutions' }
]

export const singleDirectories = {
  commissioners: `${apiV1}/directories/commissioners/`,
  diiaDirectories: id => `${apiV1}/directories/diia/${id ? `${id}/` : ''}`,
  diiaBranchOffices: id => `${apiV1}/directories/diia/branch_offices/${id ? `${id}/` : ''}`,
  diiaRanks: id => `${apiV1}/directories/diia/ranks/${id ? `${id}/` : ''}`,
  diiaEducationalInstitutions: id => `${apiV1}/directories/diia/educational_institutions/${id ? `${id}/` : ''}`,
  diiaReasons: id => `${apiV1}/directories/diia/reasons/${id ? `${id}/` : ''}`,
  diiaEducationalSpecialities: id => `${apiV1}/directories/diia/educational_specialities/${id ? `${id}/` : ''}`,
  diiaPositions: id => `${apiV1}/directories/diia/positions/${id ? `${id}/` : ''}`,
  diiaTypeCertificates: id => `${apiV1}/directories/diia/type_certificates/${id ? `${id}/` : ''}`,
  diiaFunctionLimitations: id => `${apiV1}/directories/diia/limitations_for_function/${id ? `${id}/` : ''}`,
  diiaLimitations: id => `${apiV1}/directories/diia/limitations/${id ? `${id}/` : ''}`,
  diiaPositionLimitations: id => `${apiV1}/directories/diia/limitations_for_position/${id ? `${id}/` : ''}`,
  diiaStatementDocuments: id => `${apiV1}/directories/diia/type_document_for_statement/${id ? `${id}/` : ''}`,
  diiaEducationalTypeDocuments: id => `${apiV1}/directories/diia/educational_type_documents/${id ? `${id}/` : ''}`,
  diiaEtiCourses: id => `${apiV1}/directories/diia/eti_courses/${id ? `${id}/` : ''}`,
  diiaSti: id => `${apiV1}/directories/diia/sti/${id ? `${id}/` : ''}`,
  diiaMedicalInstitutions: id => `${apiV1}/directories/diia/medical_institutions/${id ? `${id}/` : ''}`
}

export const client = {
  registration: `${apiV1}/clients/registration/`
}
export const users = {
  profile: `${apiV1}/accounts/me/`,
  user: id => `${apiV1}/accounts/users/${id ? `${id}/` : ''}`,
  audit: (type, id) => `${apiV1}/audit/${type}/${id ? `${id}/` : ''}`
}

export const auth = {
  general: {
    login: `${apiV1}/accounts/login/`,
  },
  eds: {
    getKey: `${apiV1}/accounts/digital_sign/auth_key/`,
    login: `${apiV1}/accounts/digital_sign/login/`
  },
  testLogin: `${apiV1}/accounts/test_login/`,
  logout: `${apiV1}/accounts/logout/`,
  idgovua: `${apiV1}/accounts/login/idgovua/`
}

export const statements = {
  client: id => `${apiV1}/requests/client/${id ? `${id}/` : ''}`,
  uploadFile: `${apiV1}/clients/photo_uploader/`,
  // Work document statement
  workDocument: id => `${apiV1}/requests/diia/work_document/${id ? `${id}/` : ''}`,
  workDocumentUploadFiles: id => `${apiV1}/requests/diia/work_document/${id}/upload_files/`,
  generateDecisionWorkDocument: id => `${apiV1}/requests/diia/work_document/${id}/generate_decision_on_statement/`,
  generateWorkDocumentStatement: id => `${apiV1}/requests/diia/work_document/${id}/generate_statement/`,
  combinedFileWorkDocumentStatement: id => `${apiV1}/requests/diia/work_document/${id}/get_documents_in_one_file/`,
  signDecisionWorkDocument: `${apiV1}/signature/diia_statement_decision/`,
  signTokenDecisionWorkDocument: `${apiV1}/signature/diia_statement_decision/token/`,
  signWorkDocumentForm: `${apiV1}/signature/diia_statement_work_document_form/`,
  signTokenWorkDocumentForm: `${apiV1}/signature/diia_statement_work_document_form/token/`,
  workDocumentComment: id => `${apiV1}/requests/diia/work_document/${id}/comment/`,
  generateWorkDocument: id => `${apiV1}/diia_documents/work_documents/${id}/generate_document/`,
  signWorkDocument: `${apiV1}/signature/diia_work_document/`,
  signTokenWorkDocument: `${apiV1}/signature/diia_work_document/token/`,
  assignLimitationsWorkDocument: id => `${apiV1}/requests/diia/work_document/${id}/assign_limitations/`,
  workDocumentStatementExtraDocument: (id, type, documentId) => {
    return `${apiV1}/requests/diia/work_document/${id}/${type}/${documentId ? `${documentId}/` : ''}`
  },
  workDocumentStatementExtraDocumentFile: (id, type, documentId, fileId) => {
    return `${apiV1}/requests/diia/work_document/${id}/${type}/${documentId}/delete_file/${fileId}/`
  },
  workDocumentStatementDependencies: id => `${apiV1}/requests/diia/work_document/${id}/document_dependencies/`,
  workDocumentStatementDependenciesValidation: id => `${apiV1}/requests/diia/work_document/${id}/check_documents_validation/`,
  generateWorkDocumentReport: `${apiV1}/requests/diia/work_document/generate_report/`,
  assignedUsers: `${apiV1}/requests/diia/work_document/available_assignation_users/`,
  allAssignedUsers: `${apiV1}/requests/diia/work_document/all_assignation_users/`,
  // Appeal statements
  appeal: (type, id) => `${apiV1}/requests/diia/${type}/${id ? `${id}/` : ''}`,
  appealUploadFiles: (type, id) => `${apiV1}/requests/diia/${type}/${id}/upload_files/`,
  appealAssignedUsers: type => `${apiV1}/requests/diia/${type}/available_commission_users/`,
  signAppealStatement: type => `${apiV1}/signature/${type}/`,
  signTokenAppealStatement: type => `${apiV1}/signature/${type}/token/`,
  generateAppealDecision: (type, id) => `${apiV1}/requests/diia/${type}/${id}/generate_decision_on_statement/`,
  generateAppealBlank: (type, id) => `${apiV1}/requests/diia/${type}/${id}/generate_application_form/`,
  signAppealBlank: type => `${apiV1}/signature/diia_statement_${type}_application_form/`,
  signTokenAppealBlank: type => `${apiV1}/signature/diia_statement_${type}_application_form/token/`,
  // Examination/Professional competence statement
  examination: id => `${apiV1}/requests/diia/exam/${id ? `${id}/` : ''}`,
  signExamination: `${apiV1}/signature/diia_statement_exam/`,
  signTokenExamination: `${apiV1}/signature/diia_statement_exam/token/`,
  examinationIdentityDocuments: (id, documentId) => `${apiV1}/requests/diia/exam/${id}/identity_documents/${documentId ? `${documentId}/` : ''}`,
  examinationIdentityDocumentsUploadFiles: (id, documentId) => `${apiV1}/requests/diia/exam/${id}/identity_documents/${documentId}/upload_files/`,
  examinationIdentityDocumentsRemoveFile: (id, documentId, fileId) => `${apiV1}/requests/diia/exam/${id}/identity_documents/${documentId}/delete_file/${fileId}/`,
  generateExaminationBlank: id => `${apiV1}/requests/diia/exam/${id}/generate_application_form/`,
  signExaminationBlank: `${apiV1}/signature/diia_statement_exam_application_form/`,
  signTokenExaminationBlank: `${apiV1}/signature/diia_statement_exam_application_form/token/`,
  examinationBlankArchive: id => `${apiV1}/requests/diia/exam/${id}/download_archive/`,
  examinationExamResultArchive: id => `${apiV1}/requests/diia/exam/${id}/download_exam_result_archive/`,
  // Information correction statement
  informationCorrection: id => `${apiV1}/requests/diia/change_request/${id ? `${id}/` : ''}`,
  informationCorrectionIdentityDocuments: (id, documentId) => `${apiV1}/requests/diia/change_request/${id}/identity_documents/${documentId ? `${documentId}/` : ''}`,
  informationCorrectionIdentityDocumentsUploadFiles: (id, documentId) => `${apiV1}/requests/diia/change_request/${id}/identity_documents/${documentId}/upload_files/`,
  informationCorrectionIdentityDocumentsRemoveFile: (id, documentId, fileId) => `${apiV1}/requests/diia/change_request/${id}/identity_documents/${documentId}/delete_file/${fileId}/`,
  informationCorrectionAvailableDocuments: uuid => `${apiV1}/requests/diia/change_request/available_documents/${uuid}/`,
  generateInformationCorrectionBlank: id => `${apiV1}/requests/diia/change_request/${id}/generate_application_form/`,
  signInformationCorrectionBlank: `${apiV1}/signature/diia_change_request_application_form/`,
  signTokenInformationCorrectionBlank: `${apiV1}/signature/diia_change_request_application_form/token/`,
  informationCorrectionBlankArchive: id => `${apiV1}/requests/diia/change_request/${id}/download_archive/`
}

export const documents = {
  workDocument: id => `${apiV1}/diia_documents/work_documents/${id ? `${id}/` : ''}`,
  workDocumentArchive: id => `${apiV1}/diia_documents/work_documents/${id}/download_archive/`,
  etiCertificate: id => `${apiV1}/diia_documents/certificate_eti/${id ? `${id}/` : ''}`,
  etiCertificateUploadFiles: id => `${apiV1}/diia_documents/certificate_eti/${id}/upload_files/`,
  etiCertificateRemoveFile: (id, fileId) => `${apiV1}/diia_documents/certificate_eti/${id}/delete_file/${fileId}/`,
  etiCertificateArchive: id => `${apiV1}/diia_documents/certificate_eti/${id}/download_archive/`,
  etiCertificateAvailableCourses: id => `${apiV1}/diia_documents/certificate_eti/${id ? `${id}/` : ''}available_courses/`
}

export const media = {
  media: `${apiV1}/media/`
}
