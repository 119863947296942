import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/configs/translations/en'
import uk from '@/configs/translations/uk'
Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'uk',
  fallbackLocale: 'uk',
  messages: { en, uk }
})

export default i18n
