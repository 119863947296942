import api from '@/plugins/api'
import { users as endpoints } from '@/configs/endpoints'
import { successCodes } from '@/configs/constants'

export default {
  state: () => ({
    users: {},
    user: {},
    audit: {},
    auditOpen: {},
    isLoading: false
  }),
  mutations: {
    SET_DATA_USERS (state, data) {
      Object.entries(data).forEach(([key, value]) => state[key] = value)
    }
  },
  actions: {
    // USERS
    async getUsers ({ commit }, params) {
      commit('SET_DATA_USERS', { isLoading: true })
      const response =  await api.get(endpoints.user() + params)
      if (successCodes.includes(response?.code)) commit('SET_DATA_USERS', { users: response.data } )
      commit('SET_DATA_USERS', { isLoading: false })
      return response
    },
    async getUser ({ commit }, id) {
      commit('SET_DATA_USERS', { isLoading: true })
      const response =  await api.get(endpoints.user(id))
      if (successCodes.includes(response?.code)) commit('SET_DATA_USERS', { user: response.data } )
      commit('SET_DATA_USERS', { isLoading: false })
      return response
    },
    async setUser ({ commit }, body) {
      commit('SET_DATA_USERS', { isLoading: true })
      const response = await api.post(endpoints.user(), { body })
      commit('SET_DATA_USERS', { isLoading: false })
      return response
    },
    async editUser ({ commit }, { id, body }) {
      commit('SET_DATA_USERS', { isLoading: true })
      const response = await api.patch( endpoints.user(id), { body })
      if (successCodes.includes(response?.code)) commit('SET_DATA_USERS', { user : response.data })
      commit('SET_DATA_USERS', { isLoading: false })
      return response
    },

    // AUDIT
    async getAudits ({ commit }, { type, params }) {
      commit('SET_DATA_USERS', { isLoading: true } )
      const response = await api.get(endpoints.audit(type) + params)
      successCodes.includes(response?.code) && commit('SET_DATA_USERS', { audit: response.data } )
      commit('SET_DATA_USERS', { isLoading: false } )
      return response
    },
    async getAuditDetails ({ commit }, { type, id }) {
      commit('SET_DATA_USERS', { isLoading: true } )
      const response = await api.get(endpoints.audit(type, id))
      successCodes.includes(response?.code) && commit('SET_DATA_USERS', { auditOpen: response.data } )
      commit('SET_DATA_USERS', { isLoading: false } )
      return response
    }
  }
}
