import Vue from 'vue'
import Router from 'vue-router'
import statements from './statement.routes'
import document from './document.routes'
import admin from './admin.routes'
import store from '@/store'
import { access } from '@/configs/permissions'
import { checkUrlAndToken } from '@/configs'
Vue.use(Router)

export const routes = [
  ...document,
	...statements,
	...admin,
	{
    path: '/login',
    name: 'login',
    meta: { public: true, access: 'public' },
    component: () => import('@/views/SignInEDS')
	},
  {
    path: '/login/id-gov-ua',
    redirect: to => ({ name: 'login', query: to.query })
  },
  {
    path: '*',
    name: 'all',
    redirect: '/404',
  },
  {
    path: '/404',
    name: 'no-found',
    meta: { public: true, access: 'public' },
    component: () => import('@/views/NotFound')
  },
  {
    path: '/',
    name: 'main',
    meta: { access: 'public' },
    component: () => import('@/views/Main')
  }
]
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  store.commit('SET_DATA_STATE_APP', { isLoad: false })
  if (checkUrlAndToken()) {
    const isExistUser = store.state.profile.user && Object.keys(store.state.profile.user).length
    if (!isExistUser) await store.dispatch('getUserInfo')

    if (['no-found', 'server-error'].includes(to.name)) next() // Access to public pages from an authorized account
    else if (to.meta.public) next('/') // Authorized user went to a public page (ex. login page)
    else if (access(to.meta.access)) next() // The user has access to the page
    else next('/404') // The user does not have permission to view the page
  }
  else if (to.name === 'login') next()
  else next('/login') // An unauthorized user tries to access a non-public page
  store.commit('SET_DATA_STATE_APP', { isLoad: true, previousRoute: from })
})

export default router
