import i18n from '@/plugins/vue-i18n'
import { access } from '@/configs/permissions'

export default [
  {
    path: '/statement-work-document',
    name: 'statement-work-document',
    meta: { access: 'statement-work-document' },
    component: () => import('@/views/Statement/WorkDocument/Table.vue')
  },
  {
    path: '/statement-work-document/create',
    name: 'statement-work-document-create',
    meta: {
      access: 'statement-work-document-create',
      breadcrumbs: () => ([
        { href: '/statement-work-document', text: i18n.t('menu.qualificationDocumentReceiving') },
        { href: '#', text: i18n.t('menu.workDocumentStatementCreate') }
      ])
    },
    component: () => import('@/views/Statement/WorkDocument/Form.vue')
  },
  {
    path: '/statement-work-document/:id',
    name: 'statement-work-document-info',
    meta: {
      access: 'statement-work-document',
      breadcrumbs: () => ([
        { href: '/statement-work-document', text: i18n.t('menu.qualificationDocumentReceiving') },
        { href: '#', text: i18n.t('menu.workDocumentStatement') }
      ])
    },
    component: () => import('@/views/Statement/WorkDocument/Document.vue')
  },
  {
    path: '/statement-work-document/:id/edit',
    name: 'statement-work-document-edit',
    meta: {
      access: 'statement-work-document-edit',
      breadcrumbs: () => ([
        { href: '/statement-work-document', text: i18n.t('menu.qualificationDocumentReceiving') },
        { href: '#', text: i18n.t('menu.workDocumentStatementEditing') }
      ])
    },
    component: () => import('@/views/Statement/WorkDocument/Edit.vue')
  },
  {
    path: '/appeal-statements',
    redirect: () => {
      if (access('statement-appeal')) {
        const appealType = access('statement-work-document-decision-appeal') ? 'work-document-decision' : 'professional-competence'
        return `/appeal-statements/${appealType}`
      } else return '/404'
    }
  },
  {
    path: '/appeal-statements/:type(work-document-decision|professional-competence)',
    name: 'statement-appeal',
    meta: { access: 'statement-appeal' },
    component: () => import('@/views/Statement/Appeal/Table.vue')
  },
  {
    path: '/appeal-statements/:type(work-document-decision|professional-competence)/create',
    name: 'statement-appeal-create',
    meta: {
      access: 'statement-appeal-create',
      breadcrumbs: type => ([
        { href: `/appeal-statements/${type}`, text: i18n.t('menu.decisionAppeal') },
        { href: '#', text: i18n.t('menu.decisionAppealStatementCreate') }
      ])
    },
    component: () => import('@/views/Statement/Appeal/Create.vue')
  },
  {
    path: '/appeal-statements/:type(work-document-decision|professional-competence)/:id',
    name: 'statement-appeal-info',
    meta: {
      access: 'statement-appeal',
      breadcrumbs: type => ([
        { href: `/appeal-statements/${type}`, text: i18n.t('menu.decisionAppeal') },
        { href: '#', text: i18n.t('tooltip.info') }
      ])
    },
    component: () => import('@/views/Statement/Appeal/Document.vue')
  },
  {
    path: '/statement-professional-competence',
    name: 'statement-professional-competence',
    meta: { access: 'statement-professional-competence' },
    component: () => import('@/views/Statement/ProfessionalCompetence/Table.vue')
  },
  {
    path: '/statement-professional-competence/create',
    name: 'statement-professional-competence-create',
    meta: {
      access: 'statement-professional-competence-create',
      breadcrumbs: () => ([
        { href: '/statement-professional-competence', text: i18n.t('menu.professionalCompetenceCheckStatements') },
        { href: '#', text: i18n.t('menu.professionalCompetenceCheckStatementCreate') }
      ])
    },
    component: () => import('@/views/Statement/ProfessionalCompetence/Form.vue')
  },
  {
    path: '/statement-professional-competence/:id',
    name: 'statement-professional-competence-info',
    meta: {
      access: 'statement-professional-competence',
      breadcrumbs: () => ([
        { href: '/statement-professional-competence', text: i18n.t('menu.professionalCompetenceCheckStatements') },
        { href: '#', text: i18n.t('tooltip.info') }
      ])
    },
    component: () => import('@/views/Statement/ProfessionalCompetence/Document.vue')
  },
  {
    path: '/statement-professional-competence/:id/edit',
    name: 'statement-professional-competence-edit',
    meta: {
      access: 'statement-professional-competence-edit',
      breadcrumbs: () => ([
        { href: '/statement-professional-competence', text: i18n.t('menu.professionalCompetenceCheckStatements') },
        { href: '#', text: i18n.t('menu.professionalCompetenceCheckStatementEdit') }
      ])
    },
    component: () => import('@/views/Statement/ProfessionalCompetence/Form.vue')
  },
  {
    path: '/statement-information-correction',
    name: 'statement-information-correction',
    meta: { access: 'statement-information-correction' },
    component: () => import('@/views/Statement/InformationCorrection/Table.vue')
  },
  {
    path: '/statement-information-correction/create',
    name: 'statement-information-correction-create',
    meta: {
      access: 'statement-information-correction-create',
      breadcrumbs: () => ([
        { href: '/statement-information-correction', text: i18n.t('menu.informationCorrectionStatements') },
        { href: '#', text: i18n.t('menu.informationCorrectionStatementCreate') }
      ])
    },
    component: () => import('@/views/Statement/InformationCorrection/Form.vue')
  },
  {
    path: '/statement-information-correction/:id',
    name: 'statement-information-correction-info',
    meta: {
      access: 'statement-information-correction',
      breadcrumbs: () => ([
        { href: '/statement-information-correction', text: i18n.t('menu.informationCorrectionStatements') },
        { href: '#', text: i18n.t('tooltip.info') }
      ])
    },
    component: () => import('@/views/Statement/InformationCorrection/Document.vue')
  },
  {
    path: '/statement-information-correction/:id/edit',
    name: 'statement-information-correction-edit',
    meta: {
      access: 'statement-information-correction-edit',
      breadcrumbs: () => ([
        { href: '/statement-information-correction', text: i18n.t('menu.informationCorrectionStatements') },
        { href: '#', text: i18n.t('menu.informationCorrectionStatementEdit') }
      ])
    },
    component: () => import('@/views/Statement/InformationCorrection/Form.vue')
  },
]
