import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        white: '#FFFFFF',
        black: '#212529',
        success: '#12B76A',
        error: '#E82447',
        primary: '#026B81',
        'primary-1': '#026B81',
        secondary: '#7DC6D6',
        'secondary-2': '#B9DEE7',
        'gray-1': '#F2F2F2',
        'gray-2': '#E5E5E5',
        'gray-3': '#717172',
        'gray-4': '#6C727D',
        yellow: '#EDC021'
      }
    }
  }
})
