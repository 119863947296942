export default {
  state: () => ({
    isLoad: false,
    previousRoute: null,
    lang: 'uk'
  }),
  mutations: {
    SET_DATA_STATE_APP (state, data) {
      Object.entries(data).forEach(([key, value]) => state[key] = value)
    }
  }
}
