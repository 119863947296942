<template lang="pug">
include ../../configs/templatesPUG/template
div.tableLayout
  div.fs-11.gray-3--text.my-4 {{ $t('recordsFound') }}:
    span.primary--text.ml-1 {{ pageCount || 0 }}
  v-data-table(
    :headers="headers"
    :items="items"
    :items-per-page="50"
    :loading="isLoad"
    :loading-text="$t('loading-text')"
    :no-results-text="$t('no-results-text')"
    :no-data-text="$t(noDataText)"
    :custom-sort="items => items"
    hide-default-footer
    :show-select="showSelect"
    item-key="id"
    @update:options="updateSorting"
    @click:row="item => $emit('openDetails', item)").custom-table

    template(#item.fullNameUser="{ item }")
      span.overflow-ellipsis {{ item.last_name }} {{ item.first_name }} {{ item.middle_name }}

    template(#item.rank="{ item }")
      span.overflow-ellipsis {{ directoryById('rank', item.rank)?.name_ukr }}

    template(#item.diia_position="{ item }")
      span.overflow-ellipsis {{ directoryById('diiaPositions', item.position)?.name_ukr }}

    template(#item.diia_function="{ item }")
      span.overflow-ellipsis {{ directoryById('diiaFunctions', item.function)?.name_ukr }}

    template(#item.diia_decision="{ item }")
      span.overflow-ellipsis {{ directoryById('diiaDecisions', item.diia_decision)?.name_ukr }}

    template(#item.sti_institution="{ item }")
      span.overflow-ellipsis {{ directoryById('diiaSti', item.sti_institution)?.name_ukr }}

    template(#item.diia_eti_course="{ item }")
      span.overflow-ellipsis {{ directoryById('diiaEtiCourses', item.diia_eti_course)?.name_ukr }}

    template(#item.full_strict_blank="{ item }")
      span {{ item.letter_strict_blank }} {{ item.number_strict_blank }}

    template(#item.status_document="{ item }")
      div.status-chip
        v-badge(:color="statuses(item.status_document).color" inline dot)
        span.black--text.ml-2 {{ directoryById('statusDocument', item.status_document)?.name_ukr }}

    template(#item.is_active="{ item }")
      div.status-chip
        v-badge(:color="statuses(item.is_active ? 2 : 6).color" inline dot)
        span.black--text.ml-2 {{ item.is_active ? 'Активний' : 'Заблокований' }}

    template(#item.is_signed_by_me="{ item }")
      span {{ $t(item.is_signed_by_me ? 'yes' : 'no') }}

    template(#item.review_deadline="{ item }")
      span {{ getDateFormat(item.review_deadline) }}

    template(#item.date_start="{ item }")
      span {{ getDateFormat(item.date_start) }}

    template(#item.date_end="{ item }")
      span {{ getDateFormat(item.date_end) }}

    template(#item.exam_date="{ item }")
      span {{ convertDateTime(item.exam_date) }}

    template(#item.type_user="{ item }")
      span.overflow-ellipsis {{ directoryById('typesUser', item.type_user)?.name_ukr }}

    template(#item.audit_type_user="{ item }")
      span.overflow-ellipsis {{ directoryById('typesUser', item.object_info.type_user)?.name_ukr }}

    template(#item.audit_content_type_name="{ item }")
      span(v-if="$route.params.type === 'directories'").overflow-ellipsis {{ typeDirectoryList.find(value => value.content_type_name === item.object_info.content_type_name)?.name_ukr }}
      span(v-else-if="$route.params.type === 'requests'").overflow-ellipsis {{ typeStatementsList.find(value => value.content_type_name === item.object_info.content_type_name)?.name_ukr }}
      span(v-else-if="$route.params.type === 'documents'").overflow-ellipsis {{ typeDocumentsList.find(value => value.content_type_name === item.object_info.content_type_name)?.name_ukr }}

    template(#item.audit_author="{ item }")
      span {{ item.author?.full_name }} (ID: {{ item.author?.id }})

    template(#item.audit_user="{ item }")
      span {{ item.object_info?.name }} (ID: {{ item.object_info?.id }})

    template(#item.assigned_user="{ item }")
      span {{ item.assigned_user?.last_name }} {{ item.assigned_user?.first_name }} {{ item.assigned_user?.userprofile.middle_name }}

    template(#item.state="{ item }")
      span.overflow-ellipsis {{ directoryById('operationsStates', item.state)?.name_ukr }}

    template(#item.diia_type_document="{ item }")
      span {{ directoryById('diiaDocumentTypes', item.diia_type_document)?.name_ukr }}
      span(v-if="item.diia_type_document === 'diploma' && item.hasOwnProperty('is_continue_document')").ml-1 ({{ item.is_continue_document ? 'Підтвердження' : 'Присвоєння' }})

    template(#item.correctionStatementTypeDocument="{ item }")
      span(v-if="item.content_type === 'diiacertificateeti'") {{ $t('menu.etiCertificate') }}
      span(v-else) {{ directoryById('diiaDocumentTypes', item.diia_type_document)?.name_ukr }}

    template(#item.diia_branch_office="{ item }")
      +tooltip({ value: 'directoryById("diiaBranchOffices", item.diia_branch_office)?.name_ukr' })

    template(#item.diia_rank="{ item }")
      +tooltip({ value: 'directoryById("diiaRanks", item.diia_rank)?.name_ukr' })

    template(#item.diia_type_certificate="{ item }")
      span.overflow-ellipsis {{ directoryById('diiaTypeCertificates', item.diia_type_certificate)?.name_ukr }}

    template(#item.diia_type_statement="{ item }")
      span.overflow-ellipsis {{ directoryById('diiaStatementTypes', item.diia_type_statement)?.name_ukr }}

    template(#item.rank_and_qualification="{ item }")
      span(v-if="item.diia_rank").overflow-ellipsis {{ directoryById('diiaRanks', item.diia_rank)?.name_ukr }}
      span(v-else-if="item.diia_type_certificate").overflow-ellipsis {{ directoryById('diiaTypeCertificates', item.diia_type_certificate)?.name_ukr }}
      span(v-else) -

  v-pagination(
    v-if="totalPages >= 2"
    v-model="actualPage"
    :length="totalPages"
    :total-visible="9"
    @input="updatePagination").custom-pagination.mt-4
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import statuses from '@/configs/statuses'
import { getDateFormat, convertDateTime, queryString } from '@/configs'

export default {
  props: {
    actions: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    pageCount: { type: Number, default: 0 },
    currentPage: { type: Number, default: 1 },
    totalPages: { type: Number, default: 0 },
    isLoad: { type: Boolean, default: false },
    showSelect: { type: Boolean, default: false },
    noDataText: { type: String, default: 'no-data' }
  },
  data() {
    return {
      statuses,
      getDateFormat,
      convertDateTime,
      ordering: null
    }
  },
  computed: {
    ...mapGetters(['directoryById']),
    ...mapState({
      typeDirectoryList: state => state.directory.typeDirectoryList,
      typeStatementsList: state => state.directory.typeStatementsList,
      typeDocumentsList: state => state.directory.typeDocumentsList
    }),
    actualPage: {
      get: function() {
        return this.currentPage || 1
      },
      set: function (newValue) {
        return newValue
      }
    }
  },
  methods: {
    updatePagination (page) {
      document.getElementsByClassName('tableLayout')[0].scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => {
        const query = { ...this.$route.query, page, page_size: 20 }
        this.$router.replace({ query })
          .then(() => this.$emit('loadData', queryString({ ...query })))
          .catch(() => {})
      }, 500)
    },

    updateSorting ({ sortBy, sortDesc }) {
      if (!sortBy.length && sortDesc[0] === false) return false

      const query = Object.assign({ ...this.$route.query }, { page: 1 })
      if (sortBy[0]) Object.assign(query, { ordering: `${ sortDesc[0] ? '-' : '' }${ sortBy[0] }` })
      else if (query.ordering) delete query.ordering

      this.$router.replace({ query })
        .then(() => this.$emit('loadData', queryString(query)))
    }
  }
}
</script>
