import { mapActions } from 'vuex'

export default {
  data () {
    return {
      secondsSinceLastActivity: 0,
      maxInactivity: 1800 // 30 min
    }
  },
  methods: {
    ...mapActions(['accountLogout']),

    setupActivityCheck () {
      const interval = setInterval(() => {
        if (this.secondsSinceLastActivity >= this.maxInactivity) {
          clearInterval(interval)
          return this.accountLogout()
        } else this.secondsSinceLastActivity++
      }, 1000)

      const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart']
      activityEvents.forEach(event => {
        document.addEventListener(event, () => this.secondsSinceLastActivity = 0, true)
      })
    }
  }
}
