import Vue from 'vue'
import Vuex from 'vuex'
import App from './modules/app'
import Sailor from './modules/sailor'
import Directory from './modules/directory'
import Profile from './modules/profile'
import Users from './modules/users'
import Statement from './modules/statement'
import Document from './modules/document'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: App,
    sailor: Sailor,
    directory: Directory,
    profile: Profile,
    users: Users,
    statement: Statement,
    document: Document,
  }
})

export default store
