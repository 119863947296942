import { i18n } from '@/plugins/vue-i18n.js'
import '@/assets/sass/notifications.sass'

export default {
  global: {
    close: true,
    pauseOnHover: true,
    timeout: 3000,
    progressBar: true,
    layout: 2,
    position: 'topRight'
  },

  info: {
    iconUrl: '/iconsNotification/info.svg',
    title: `${i18n.t('notify.title.info')}!`,
  },

  success: {
    iconUrl: '/iconsNotification/success.svg',
    title: `${i18n.t('notify.title.success')}!`,
  },

  warning: {
    iconUrl: '/iconsNotification/warning.svg',
    title: `${i18n.t('notify.title.warning')}!`,
  },

  error: {
    iconUrl: '/iconsNotification/error.svg',
    title: `${i18n.t('notify.title.error')}!`,
  }
}
