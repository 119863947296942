import notify from '@ananasbear/notify'

const processingError = (code, data) => {
  if (code > 399 && code < 500) {
    // Temporary error skip
    if (data.detail === 'Client not found') return false

    let ArrayString = recursion(data)
    ArrayString.forEach((errorText) => notify.error(`notify.${errorText.replaceAll('.', ' ')}`))
  }
  else if (code === 500) notify.error(`notify.serverError`)
}

const recursion = (item, arrayString = []) => {
  if (['string', 'number'].includes(typeof item)) {
    arrayString.push(item)
  } else {
    Object.entries(item).forEach(([key]) => recursion(item[key], arrayString))
  }
  return arrayString
}

export default processingError
