<template lang="pug">
div
  div(v-if="label").fs-11.font-u--l.black--text.mb-2 {{ label }}
    span(v-if="isRequired").primary--text.ml-1 *
  v-menu(
    v-model="menu"
    transition="scale-transition"
    content-class="box-shadow-none"
    :close-on-content-click="false"
    max-width="290"
    :nudge-bottom="4"
    offset-y
    :attach="attach")
    template(#activator="{ on }")
      v-text-field(
        v-if="range"
        :value="formattedDate"
        :placeholder="placeholder || label"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        :disabled="disabled"
        readonly
        clearable
        outlined
        dense
        solo
        flat
        v-on="on"
        @click:clear="onClear"
        @click="menu = true"
        @blur="$emit('blur', $event)").custom-input.pointer
        template(#append)
          img(src="@/assets/icons/calendar.svg" width="20" height="20" alt="calendar" v-on="on" @click="menu = true")
      v-text-field(
        v-else
        :value="value"
        :placeholder="placeholder || label"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        :disabled="disabled"
        type="date"
        max="9999-12-31"
        clearable
        outlined
        dense
        solo
        flat
        @click:clear="onClear"
        @blur="$emit('blur', $event)"
        @input="onInput").custom-input.pointer
        template(#append)
          img(src="@/assets/icons/calendar.svg" width="20" height="20" alt="calendar" v-on="on" @click="menu = true")
    v-date-picker(
      :value="value"
      first-day-of-week="1"
      color="primary"
      locale="uk"
      :range="range"
      :min="min"
      :max="max"
      :disabled="disabled"
      :allowed-dates="allowedDates"
      no-title
      scrollable
      @input="onInput").custom-date-picker
</template>

<script>
import { getDateFormat } from '@/configs'

export default {
  props: {
    value: { type: [String, Array, Object] },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    min: { type: String, default: '1900-01-01' },
    max: { type: String, default: '2200-01-01' },
    errorMessages: { type: Array, default: () => [] },
    isRequired: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    range: { type: Boolean, default: false },
    isDisabledWeekends: { type: Boolean, default: false },
    attach: { type: Boolean, default: true }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    formattedDate () {
      return this.value.map(item => getDateFormat(item)).join(' - ')
    }
  },
  methods: {
    onInput (val) {
      if (!this.range || (this.range && val?.length === 2)) this.menu = false
      this.$emit('input', val)
    },
    onClear () {
      this.$emit('input', this.range ? [] : null)
      this.$emit('clear')
    },
    allowedDates (date) {
      const day = new Date(date).getDay()
      return this.isDisabledWeekends ? [1,2,3,4,5].includes(day) : true
    }
  }
}
</script>
