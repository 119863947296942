import i18n from '@/plugins/vue-i18n'

export default [
  {
    path: '/qualification-documents',
    name: 'qualification-documents',
    meta: { access: 'qualification-documents' },
    component: () => import('@/views/Document/Qualification/Table.vue')
  },
  {
    path: '/qualification-documents/:id',
    name: 'qualification-documents-info',
    meta: {
      access: 'qualification-documents',
      breadcrumbs: () => ([
        { href: '/qualification-documents', text: i18n.t('menu.qualificationDocuments') },
        { href: '#', text: i18n.t('tooltip.info') }
      ])
    },
    component: () => import('@/views/Document/Qualification/Document.vue')
  },
  {
    path: '/eti-certificates',
    name: 'eti-certificates',
    meta: { access: 'eti-certificates' },
    component: () => import('@/views/Document/CertificateETI/Table.vue')
  },
  {
    path: '/eti-certificates/create',
    name: 'eti-certificates-create',
    meta: {
      access: 'eti-certificates-processing',
      breadcrumbs: () => ([
        { href: '/eti-certificates', text: i18n.t('menu.etiCertificates') },
        { href: '#', text: i18n.t('menu.etiCertificateAdd') }
      ])
    },
    component: () => import('@/views/Document/CertificateETI/Form.vue')
  },
  {
    path: '/eti-certificates/:id',
    name: 'eti-certificates-info',
    meta: {
      access: 'eti-certificates',
      breadcrumbs: () => ([
        { href: '/eti-certificates', text: i18n.t('menu.etiCertificates') },
        { href: '#', text: i18n.t('tooltip.info') }
      ])
    },
    component: () => import('@/views/Document/CertificateETI/Document.vue')
  },
  {
    path: '/eti-certificates/:id/edit',
    name: 'eti-certificates-edit',
    meta: {
      access: 'eti-certificates-processing',
      breadcrumbs: () => ([
        { href: '/eti-certificates', text: i18n.t('menu.etiCertificates') },
        { href: '#', text: i18n.t('menu.etiCertificateEdit') }
      ])
    },
    component: () => import('@/views/Document/CertificateETI/Form.vue')
  }
]
