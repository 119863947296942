export default {
  textToSpeechLang: 'uk-UA',
  speechToTextLang: 'uk-UA',
  labels: {
    resetTitle: 'Скидання',
    closeTitle: 'Закрити',
    menuTitle: 'Опції доступності',
    increaseText: 'Збільшити розмір тексту',
    decreaseText: 'Зменшити розмір тексту',
    increaseTextSpacing: 'Збільшити міжрядковий інтервал',
    decreaseTextSpacing: 'Зменшити міжрядковий інтервал',
    increaseLineHeight: 'Збільшити висоту рядка',
    decreaseLineHeight: 'Зменшити висоту рядка',
    invertColors: 'Інвертувати кольори',
    grayHues: 'Сірі відтінки',
    underlineLinks: 'Підкреслити посилання',
    bigCursor: 'Великий курсор',
    readingGuide: 'Посібник для читання',
    textToSpeech: 'Озвучування тексту',
    speechToText: 'Перетворення звуків у текст',
    disableAnimations: 'Вимкнути анімацію'
  },
  modules: {
    increaseTextSpacing: false,
    decreaseTextSpacing: false,
    increaseLineHeight: false,
    decreaseLineHeight: false
  }
}
