<template lang="pug">
div
  div(v-if="label").fs-11.font-u--l.black--text.mb-2 {{ label }}
    span(v-if="isRequired").primary--text.ml-1 *
  v-menu(
    v-model="menu"
    transition="scale-transition"
    content-class="box-shadow-none"
    :close-on-content-click="false"
    max-width="290"
    :nudge-bottom="4"
    offset-y
    attach)
    template(#activator="{ on }")
      v-text-field(
        :value="value"
        placeholder="00:00"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        :disabled="disabled"
        readonly
        clearable
        outlined
        dense
        solo
        flat
        v-on="on"
        @click:clear="onClear"
        @click="menu = true"
        @blur="$emit('blur', $event)").custom-input.pointer
    v-time-picker(
      v-if="menu"
      :value="value"
      :min="min"
      :max="max"
      :disabled="disabled"
      color="primary"
      format="24hr"
      no-title
      @input="onInput"
      @click:minute="menu = false")
</template>

<script>
export default {
  props: {
    value: { type: [String, Object] },
    label: { type: String, default: '' },
    min: { type: String, default: '00:00' },
    max: { type: String, default: '24:00' },
    errorMessages: { type: Array, default: () => [] },
    isRequired: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
      menu: false
    }
  },
  methods: {
    onInput (val) {
      this.$emit('input', val)
    },
    onClear () {
      this.$emit('input', '')
      this.$emit('clear')
    }
  }
}
</script>
