import store from '@/store'
let role = {}

export const initPermissions = () => {
  role = {
    admin: store.state.profile.user.type_user === 'admin',
    maradHeadAdmin: store.state.profile.user.type_user === 'marad_head_admin',
    authOfficialSrb: store.state.profile.user.type_user === 'auth_official_srb',
    dpd: store.state.profile.user.type_user === 'dpd',
    maradAuthPerson: store.state.profile.user.type_user === 'marad_auth_person',
    marad: store.state.profile.user.type_user === 'marad_head_registry',
    registry: store.state.profile.user.type_user === 'registry',
    secretarySQC: store.state.profile.user.type_user === 'secretary_sqc',
    headMaradSQC: store.state.profile.user.type_user === 'head_marad_sqc',
    support: store.state.profile.user.type_user === 'support_marad',
    officialMorrichservice: store.state.profile.user.type_user === 'official_morrichservice',
    officialServiceCenter: store.state.profile.user.type_user === 'official_service_center',
    secretaryAppealCommission: store.state.profile.user.type_user === 'secretary_appeal_commission',
    shippingAdministration: store.state.profile.user.type_user === 'shipping_administration',
    memberAppeal: store.state.profile.user.type_user === 'member_appeal',
    memberMaradSqc: store.state.profile.user.type_user === 'member_marad_sqc',
    security: store.state.profile.user.type_user === 'security',
    maradManager: store.state.profile.user.type_user === 'marad_manager',
    secretaryETI: store.state.profile.user.type_user === 'secretary_eti'
  }
}

export const access = (typeAccess) => {
  if (typeAccess === 'public') return true

  switch (typeAccess) {
    case 'registry': return role.registry
    case 'marad_auth_person': return role.maradAuthPerson
    case 'marad': return role.marad
    case 'secretary_dpd': return role.dpd
    case 'secretary_sqc': return role.secretarySQC
    case 'support': return role.support
    case 'head_marad_sqc': return role.headMaradSQC
    case 'official_morrichservice': return role.officialMorrichservice
    case 'secretary_appeal_commission': return role.secretaryAppealCommission
    case 'shipping_administration': return role.shippingAdministration
    case 'member_appeal': return role.memberAppeal
    case 'member_marad_sqc': return role.memberMaradSqc
    case 'marad_head_admin': return role.maradHeadAdmin
    case 'security': return role.security
    case 'marad_manager': return role.maradManager
    case 'secretary_eti': return role.secretaryETI

    // DOCUMENTS
    case 'qualification-documents': return role.maradAuthPerson || role.shippingAdministration || role.maradManager
    case 'eti-certificates': return role.secretaryETI || role.shippingAdministration || role.maradManager
    case 'eti-certificates-processing': return role.secretaryETI
    case 'eti-certificates-status-change': return role.secretaryETI || role.shippingAdministration
    // STATEMENTS
    case 'statement-work-document': return role.headMaradSQC || role.secretarySQC || role.maradAuthPerson ||
      role.officialMorrichservice || role.secretaryAppealCommission || role.shippingAdministration
    case 'statement-work-document-create':
    case 'statement-work-document-edit': return role.officialMorrichservice || role.shippingAdministration
    case 'statement-appeal':
    case 'statement-professional-competence-appeal': return role.secretaryAppealCommission || role.shippingAdministration ||
      role.headMaradSQC || role.memberAppeal || role.memberMaradSqc
    case 'statement-work-document-decision-appeal': return role.secretaryAppealCommission || role.shippingAdministration ||
      role.headMaradSQC || role.memberAppeal
    case 'statement-appeal-create': return role.shippingAdministration
    case 'statement-appeal-processing': return role.secretaryAppealCommission
    case 'statement-professional-competence': return role.shippingAdministration || role.officialMorrichservice ||
      role.memberMaradSqc || role.headMaradSQC || role.secretaryAppealCommission
    case 'statement-professional-competence-create':
    case 'statement-professional-competence-edit': return role.shippingAdministration || role.officialMorrichservice
    case 'statement-information-correction': return role.shippingAdministration || role.maradManager || role.secretaryETI
    case 'statement-information-correction-create':
    case 'statement-information-correction-edit': return role.shippingAdministration
    // OTHER
    case 'users':
    case 'directories': return role.admin || role.maradHeadAdmin
    case 'audit': return role.admin || role.maradHeadAdmin || role.security
    default: return false

  }
}

