import i18n from '@/plugins/vue-i18n'

export default [
  {
    path: '/user',
    name: 'user',
    meta: { access: 'users' },
    component: () => import('@/views/User/Table')
  },
  {
    path: '/user/registration',
    name: 'user-registration',
    meta: {
      access: 'users',
      breadcrumbs: () => ([
        { href: '/user', text: i18n.t('menu.users') },
        { href: '#', text: i18n.t('menu.userRegistration') }
      ])
    },
    component: () => import('@/views/User/Create')
  },
  {
    path: '/user/:id',
    name: 'user-info',
    meta: {
      access: 'users',
      breadcrumbs: () => ([
        { href: '/user', text: i18n.t('menu.users') },
        { href: '#', text: i18n.t('menu.user') }
      ])
    },
    component: () => import('@/views/User/Document')
  },
  {
    path: '/directories',
    name: 'directories',
    meta: { access: 'directories' },
    redirect: '/directories/education-institutions'
  },
  {
    path: '/directories/:type',
    name: 'directories-by-type',
    meta: { access: 'directories' },
    component: () => import('@/views/Directories/Table')
  },
  {
    path: '/directories/:type(reasons|branch-offices|education-institutions|educational-type-documents|educational-specialities|eti-courses|sti|medical-institutions)/create',
    name: 'directories-by-type-create',
    meta: {
      access: 'directories',
      breadcrumbs: () => ([
        { href: '/directories', text: i18n.t('menu.directories') },
        { href: '#', text: i18n.t('menu.creteDirectories') }
      ])
    },
    component: () => import('@/views/Directories/Create')
  },
  {
    path: '/audit/:type',
    name: 'audit-by-type',
    meta: { access: 'audit' },
    component: () => import('@/views/Audit/Table')
  },
  {
    path: '/audit/:type/:id',
    name: 'audit-by-type-info',
    meta: { access: 'audit' },
    component: () => import('@/views/Audit/Document')
  }
]
