import { Authorization } from '@ananasbear/auth'
import { auth } from '@/configs/endpoints'
import api from '@/plugins/api'
import eds from '@/plugins/eds'

const options = {
	tokenKey: 'Token',
	tokenKeyResponse: 'token',
	urls: auth,
  apiModule: api,
  edsModule: eds
}

const authorization = new Authorization(options)

export default authorization
