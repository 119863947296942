import { API } from '@ananasbear/superapi'
import { media as endpoints } from '@/configs/endpoints'
const api = new API()

export default api

export const checkFiles = async files => {
  return await Promise.all(
    files.map(file => {
      return api.post(endpoints.media, { body: { file: file.file } }).then(response => {
        return { ...file, ...response.data }
      })
    })
  )
}

