import api from '@/plugins/api'
import { initPermissions } from '@/configs/permissions'
import { successCodes } from '@/configs/constants'
import { auth, users } from '@/configs/endpoints'
import { clearTokenValues } from '@/configs'

export default {
  state: () => ({
    user: {}
  }),
  mutations: {
    SET_DATA_PROFILE(state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },
  actions: {
    async getUserInfo({ commit }) {
      const response = await api.get(users.profile)
      if (successCodes.includes(response?.code)) {
        commit('SET_DATA_PROFILE', { user: response.data })
        initPermissions()
      }
      return response
    },
    async setToken (context, data) {
      await clearTokenValues()
      localStorage.setItem('TOKEN', `${ data.key } ${ data.token }`)
      document.cookie = `TOKEN=${ data.key } ${ data.token }; path=/; samesite=strict; max-age=28800`
    },
    async getToken ({ dispatch }, body) {
      const response = await api.post(auth.testLogin, { body })
      if (successCodes.includes(response?.code)) await dispatch('setToken', { key: 'Token', token: response.data.token })
      return response
    },
    async accountLogout () {
      const response = await api.get(auth.logout)
      if (successCodes.includes(response?.code)) {
        await clearTokenValues()
        window.location = '/login'
      }
      return response
    },
    async idgovuaAuth ({ dispatch }, body) {
      const response = await api.post(auth.idgovua, { body })
      if (successCodes.includes(response?.code)) await dispatch('setToken', { key: 'Token', token: response.data.token })
      return response
    },
  }
}
